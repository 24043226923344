export {getBTCFee} from './btc';
export {getETHFee} from './eth';
export {getNEOFee} from './neo';
export {getWAVFee} from './wav';
export {getLSKFee} from './lsk';
export {getNRGFee} from './nrg';
export {getEMBFee} from './emb';
export {getDAIFee} from './dai';
export {getLEMBFee} from './lemb';
export {getUSDTFee} from './usdt';
