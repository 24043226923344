import {saveWallet} from './helper';
import {encrypt} from '../../helpers/crypto'

var CoinKey = require('coinkey');

export function setBTCWallet(walletKey,password,done){
  return function(dispatch,getState) {
    try {
      var ck = CoinKey.fromWif(walletKey);
      let address = ck.publicAddress;
      let enc = encrypt(walletKey,password);
      saveWallet(getState(),'btc',address,enc,dispatch)
      done(null)
    }
    catch(err){
      done(err);
    }
  }
}

export function getBTCAddress(walletKey){
  return function(dispatch,getState) {
    try {
      var ck = CoinKey.fromWif(walletKey);
      let address = ck.publicAddress;
      return address;
    }
    catch(err){
      return;
    }
  }
}
