import { getBTCBalance } from './btc'
import { getETHBalance } from './eth'
import { getNEOBalance } from './neo'
import { getWAVBalance } from './wav'
import { getLSKBalance } from './lsk'
import { getNRGBalance, getSpendableOutpoints } from './nrg'
import { getEMBBalance } from './emb'
import { getDAIBalance } from './dai'
import { getUSDTBalance } from './usdt'

export function getBalancesDispatch() {
  return function(dispatch, getState) {
    getBalances(dispatch, getState())
  }
}
let lastCalled = Date.now() - 60000;

export async function getBalances(dispatch, state) {
  try {
    let wallet = state.passport.wallet

    if (wallet.emb) {
      getEMBBalance(dispatch,state)
      getNRGBalance(dispatch, state)
    }

    if (wallet.eth) getETHBalance(dispatch, state)
    if(Date.now() - lastCalled > 60000) if (wallet.btc) getBTCBalance(dispatch, state)
    if (wallet.neo) getNEOBalance(dispatch, state)
    if (wallet.wav) getWAVBalance(dispatch, state)
    if (wallet.lsk) getLSKBalance(dispatch, state)
    if (wallet.dai) getDAIBalance(dispatch, state)
    if (wallet.usdt) getUSDTBalance(dispatch, state)
    lastCalled = Date.now()

  } catch (err) {
    console.log({ err })
  }
}

export { getBTCBalanceDispatch } from './btc'
export { getETHBalanceDispatch } from './eth'
export { getNEOBalanceDispatch } from './neo'
export { getWAVBalanceDispatch } from './wav'
export { getLSKBalanceDispatch } from './lsk'
export { getNRGBalanceDispatch } from './nrg'
export { getEMBBalanceDispatch } from './emb'
export { getDAIBalanceDispatch } from './dai'
export { getUSDTBalanceDispatch } from './usdt'



export {
  getBTCBalance,
  getETHBalance,
  getNEOBalance,
  getWAVBalance,
  getLSKBalance,
  getNRGBalance,
  getSpendableOutpoints,
  getEMBBalance,
  getDAIBalance,
  getUSDTBalance,
}
