import * as passphrase from '@liskhq/lisk-passphrase';
var cryptography = require('@liskhq/lisk-cryptography');

export function genLSKWallet(done){
  return function(dispatch,getState) {
    try {
      let privateKey = passphrase.Mnemonic.generateMnemonic();
      let address = cryptography.getAddressFromPassphrase(privateKey);
      done({privateKey,address,type:'LSK'})
    }
    catch(err){
      done(err);
    }
  }
}
