import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Refresh extends Component {
  render() {
    return (
      <li>
        <a onClick={() => window.location.reload() }>
          <i className="material-icons icon" >refresh</i>
        </a>
      </li>
    );
  }
}

export default Refresh;
