import {
  FETCHED_BALANCE,
  FETCHED_NRG,
  FETCHED_EMB,
  FETCHED_LEMB,

  FETCHED_DAI,
  FETCHED_BTC,
  FETCHED_ETH,
  FETCHED_NEO,
  FETCHED_WAV,
  FETCHED_LSK,

  FETCHED_BLAKE,

  WALLET_SET,
  REMOVE_ASSET,
  REMOVE_WALLET,
  FETCHED_FEE,

  FETCHED_SPENDABLE_OUTPOINTS
} from '../actions/types';

let wallet = JSON.parse(window.localStorage.getItem('bc-wallet'))
let spendableOutpoints = JSON.parse(window.localStorage.getItem('spendable-outpoints'))

let initialState = {
  btc: {},
  neo: {},
  wav: {},
  lsk: {},
  eth: {},
  fee: 16600000000000 * 41 / Math.pow(10,18) ,
  emb: {},
  'l-emb':{},
  usdt: {},
  dai: {},
  unlockable:{},
  nrg: {},
  nrg_collateralized: {},
  spendableOutpoints : spendableOutpoints ? spendableOutpoints : {},
  wallet: wallet ? wallet : {},
};

export default function(state: Object = initialState, action) {
  switch (action.type) {
    case FETCHED_FEE:
      return { ...state, fee: action.payload}
    case FETCHED_BALANCE:
      let newstate = state;
      newstate[action.payload.asset][action.payload.address] = action.payload.balance;
      return { ...newstate}
    case FETCHED_NRG:
      newstate = state;
      newstate.nrg[action.payload.address] = {nrg: action.payload.nrg,nrg_collateralized:action.payload.nrg_collateralized,unlockable:action.payload.unlockable}
      return { ...newstate};
    case FETCHED_SPENDABLE_OUTPOINTS:
      newstate = state;
      newstate.spendableOutpoints[action.payload.address] = action.payload.outpoints;
      try {
        window.localStorage.setItem(`spendable-outpoints`,JSON.stringify(newstate.spendableOutpoints));
      }
      catch(err){

      }
      return {...newstate}
    case WALLET_SET:
      window.localStorage.setItem('bc-wallet',JSON.stringify(action.payload));
      return {...state, wallet: action.payload};
    case REMOVE_WALLET:
      window.localStorage.setItem('bc-wallet',null);
      window.localStorage.setItem('spendable-outpoints',null)
      window.localStorage.setItem('nrg-history',null)
      return {...state, wallet: {}, pendingTransactions: {},nrg:[0],nrg_collateralized:[0],btc:[0],eth:[0],emb:[0],total_emb:[0],lsk:[0],neo:[0],wav:[0]};
    case REMOVE_ASSET:
      newstate = state;
      newstate[action.payload] = [0]
      return {...newstate}
    default:
      return state;
  }
}
