import {FETCHED_HISTORY,} from '../../types';
import {deletePending} from '../../ui/passport';
import {web3} from '../../../config';
import axios from 'axios';

export function getETHTransfers(done){
  return async function(dispatch,getState){
    try {
      for(let address of Object.keys(getState().passport.wallet.eth)){
        let url = `https://api.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=1&endblock=latest&sort=asc&apikey=N9GE9PGPPPC23YW85WJ6Y1FW2DRAXPEWQM`;
        let response = await axios.get(url,{params:{cors:true}})

        if(response && response.data && response.data.result){
          let history = [];
          response.data.result.reverse().forEach(tx => {
            if(tx.to !=='' && tx.value !=="0"){
              let type = 'ETH';
              let from = tx.from;
              let to = tx.to;
              let value = tx.value / Math.pow(10,18);
              let timestamp = tx.timeStamp;
              let color = to === address.toLowerCase() ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
              if(from === to) color = 'grey';

              history.push({height:tx.blockNumber,from,timestamp,type,to,value,color,txHash:tx.hash})
            }
          });

          deletePending(history,getState(),dispatch)

          dispatch({
            type: FETCHED_HISTORY,
            payload:{history,type:'eth',address}
          })
        }
      }
    }
    catch(err){
      console.log({err})
    }
  }
}
