import React, { Component } from 'react';

class Ring extends Component {
  render() {
    return (
      <div>

        <h5 className='borderlessMain'>portfolio</h5>
        <h5 className='borderlessMain2'>by BORDERLESS</h5>

        <div className='bottom'>
          <h5 className='blockchains'>BLOCKCHAINS</h5>
        </div>
        <div className='bottom'>
          <h5 className='block'></h5>
          <h5 className='line'></h5>
          <h5 className='block2'></h5>
          <h5 className='line2'></h5>
          <h5 className='block3'></h5>
        </div>

        <div className='bottom'><div className='rotate'><h5 className='block2'></h5></div></div>
        <div className='bottom'><div className='rotateT'><h5 className='block2'></h5></div></div>
        <div className='bottom'><div className='rotate3'><h5 className='block2'></h5></div></div>
        <div className='bottom'><div className='rotate4'><h5 className='block2'></h5></div></div>
        <div className='bottom'><div className='rotate5'><h5 className='block2'></h5></div></div>


        <div className='rotate'><h5 className='blockchains'>-</h5></div>
        <div className='rotateT'><h5 className='blockchains'>-</h5></div>
        <div className='rotate3'><h5 className='blockchains'>-</h5></div>
        <div className='rotate4'><h5 className='blockchains'>-</h5></div>
        <div className='rotate5'><h5 className='blockchains'>-</h5></div>


        <div className='megarotate'>
          <div className='lineTop'></div>
          <div className='rotate'><div className='lineTop'></div></div>
          <div className='rotateT'><div className='lineTop'></div></div>
          <div className='rotate3'><div className='lineTop'></div></div>
          <div className='rotate4'><div className='lineTop'></div></div>
          <div className='rotate5'><div className='lineTop'></div></div>
        </div>

        <div className="borderUno"></div>
        <div className="borderUno"></div>
        <div className="borderDos"></div>
        <div className="borderDos"></div>
      </div>
    )
  }
}

export default Ring;
