import React, { Component } from "react";

class ShowWallet extends Component {
  constructor() {
    super();
    this.state = { show: false };
  }
  toggle() {
    this.setState({ show: !this.state.show });
  }
  renderCard(type,{address, privateKey }) {
    return (
      <div className="" style={{ textAlign: "left", width: "100%" }}>
        <img
          src={`./${type.toLowerCase()}.png`}
          style={{ height: "50px", float: "left" }}
        />
        <h4
          className="black-text"
          style={{
            marginTop: "7px",
            marginLeft: "10px",
            display: "inline-block"
          }}
        >
          Generate {type.toUpperCase()} Wallet
        </h4>
        <div style={{ textAlign: "left" }}>
          <p style={{ color: "grey" }}>Address:</p>
          <p style={{ color: "black", marginBottom:'15px'}}>{address}</p>
          <i
            className="material-icons"
            style={{
              cursor: "pointer",
              marginLeft: "10px",
              color: "grey",
              fontSize: "20px"
            }}
            onClick={this.toggle.bind(this)}
          >
            remove_red_eye
          </i>
          <p style={{ color: "grey", float: "left" }}>
            {type.toUpperCase() !=="LSK" && type.toUpperCase() !=="WAV"
              ? "Private Key"
              : "Mnemonic Phrase"}
            :
          </p>
          <p style={{ color: "black",wordBreak:'break-word',height:'50px' }}>
            {this.state.show ? privateKey : "*******************"}
          </p>

          <br />
          <p className="black-text">Enter {type.toUpperCase()} Password:</p>
          <form onSubmit={this.props.onSubmit} href="#">
            <input
              id={type.toUpperCase() + "passwordWallet"}
              className="blacktext"
              type="password"
              label={"Password:"}
              required={true}
            />
          </form>
          <p className="black-text">Confirm {type.toUpperCase()} Password:</p>
          <form onSubmit={this.props.onSubmit} href="#">
            <input
              id={type.toUpperCase() + "passwordWalletConfirm"}
              className="blacktext"
              type="password"
              label={"Password:"}
              required={true}
            />
          </form>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div style={{}}>
        {this.renderCard(this.props.type,this.props.wallet)}
      </div>
    );
  }
}

export default ShowWallet;
