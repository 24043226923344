import {
  WALLET_SET
} from '../../types';
import {genWallet} from '../../helpers/simpleWallet';
import {wallet as NeonWallet} from "@cityofzion/neon-js";

const bitcoin = require('bitcoinjs-lib')
const HDNode = require('@ont-community/hdkey-secp256r1')

const bip39 = require('bip39')

var cryptography = require('@liskhq/lisk-cryptography');
const WavesAPI = require('@waves/waves-api');

const NEO_BIP44_PATH = `m/44'/888'/0'/0/0`

export async function genAllWallet(mnem, done){
  try {
    let gen = await genWallet(mnem)
    let mnemonic = gen.btcWallet.mnemonic

    let wallet = {}

    //btc
    wallet['btc'] = gen.btcWallet
    wallet.privateKey = gen.btcWallet.privateKeyWF

    //eth, usdt, dai
    wallet['eth'] = gen.ethWallet
    wallet['usdt'] = gen.ethWallet
    wallet['dai'] = gen.ethWallet

    //lsk
    let address = cryptography.getAddressFromPassphrase(mnemonic);
    wallet['lsk'] = {address,privateKey:mnemonic}

    //wav
    const Waves = WavesAPI.create(WavesAPI.MAINNET_CONFIG);
    const seed = Waves.Seed.fromExistingPhrase(mnemonic);
    wallet['wav'] = {address:seed.address,privateKey:mnemonic}

    //neo
    const actualSeed = await bip39.mnemonicToSeed(mnemonic);
    const rootNode = HDNode.fromMasterSeed(actualSeed)

    const pathNode = rootNode.derive(NEO_BIP44_PATH)
    const pathNodeChild0 = pathNode.deriveChild(0)
    const wif = bitcoin.bip32.fromBase58(pathNodeChild0.privateExtendedKey, bitcoin.networks.bitcoin).toWIF()
    let account = new NeonWallet.Account(wif);
    address = account.address;
    wallet['neo'] = {address,privateKey:wif}

    console.log({wallet})
    done(wallet)
  }
  catch(err){
    console.log({err})
    done(err);
  }
}
