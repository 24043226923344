import {FETCHED_HISTORY,} from '../../types';
import {deletePending} from '../../ui/passport';
import axios from 'axios';
import moment from 'moment';

var bitcoin = require('bitcoinjs-lib')
var CoinKey = require('coinkey')

export function getBTCTransfers(done){
  return async function(dispatch,getState){
    let backups = [tryBlockchainInfo, tryBlockCypher];
    for(let address of Object.keys(getState().passport.wallet.btc)){
      let success = false
      //try all backup functions, if any of them are succesfull, break out of the loop
      for(let func of backups){
        success = await func(address,dispatch,getState())
        if(success) break;
      }
    }
    return
  }
}

async function tryBlockCypher(address, dispatch, state){
  try{
    let response = await axios.get(`https://api.blockcypher.com/v1/btc/main/addrs/${address}/full`,{params:{cors:true}})
    if(response && response.data && response.data.txs){
      let history = [];
      response.data.txs.forEach(tx => {
        let dontRemove = tx.block_height === -1;
        let from = tx.inputs[0].addresses[0];
        tx.outputs.forEach(out => {
          let to = out.addresses[0];
          let color = to === address ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
          if(dontRemove) color = 'yellow';
          if((from === address) &&  (to === address)) color = 'grey';
          history.push({height:tx.block_height,dontRemove,from,timestamp:moment(tx.received).unix(),type:'BTC',to,value:out.value / Math.pow(10,8),color,txHash:tx.hash})
        })
      })
      deletePending(history,state,dispatch)
      dispatch({
        type: FETCHED_HISTORY,
        payload:{history,type:'btc',address}
      })
      return true;
    }
    else return false
  }
  catch(err){
    return false
  }
}

async function tryBlockchainInfo(address, dispatch, state){
  try {
    let response = await axios.get(`https://blockchain.info/rawaddr/${address}`,{params:{cors:true}})
    if(response && response.data && response.data.txs){
      let history = [];
      response.data.txs.forEach(tx => {
        let dontRemove = !tx.block_height;
        let from = tx.inputs[0].prev_out.addr;
        tx.out.forEach(out => {
          let to = out.addr;
          let color = to === address ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
          if(dontRemove) color = 'yellow';
          if((from === address) &&  (to === address)) color = 'grey';
          history.push({height:tx.block_height,dontRemove,from,timestamp:tx.time,type:'BTC',to,value:out.value / Math.pow(10,8),color,txHash:tx.hash})
        })
      })
      deletePending(history,state,dispatch)
      dispatch({
        type: FETCHED_HISTORY,
        payload:{history,type:'btc',address}
      })
      return true;
    }
    else return false
  }
  catch(err){
    return false;
  }
}
