import {FETCHED_FEE} from '../../types';
import {getGasPrice} from '../../helpers/web3';

export function getUSDTFee(amount,done){
	return async function(dispatch,getState) {
    let gasPrice = await getGasPrice()
    dispatch({
      type:FETCHED_FEE,
      payload: `${gasPrice*58000 / Math.pow(10,18)}`
    })
		if(done) done();
  }
}
