import React, { Component } from 'react';
import Title from '../../helper/title';
import Asset from './asset.jsx';
import './asset.css';
import {assets} from '../../../config';

class Assets extends Component {
  render() {
    return (
      <div className="col s3 assets" style={{padding:0}}>
        <Title text='assets' />
        <div className="col s12" id='wallets' >
          {assets.map((a,index)=>(
            <Asset key={index} index={index} title={a.toLowerCase()} asset={a.toLowerCase()} div={`${a.toLowerCase()}div`} />
          ))}
        </div>
      </div>
    )
  }
}

export default Assets;
