import {FETCHED_HISTORY,} from '../../types';
import {deletePending} from '../../ui/passport';
import {web3,DAI_ADDRESS} from '../../../config';
import axios from 'axios';

export function getDAITransfers(done){
  return async function(dispatch,getState){
    try {
      for(let address of Object.keys(getState().passport.wallet.dai)){
        let url = 'https://api.etherscan.io/api?module=logs&action=getLogs'+
        "&fromBlock=1" +
        "&toBlock=latest" +
        `&address=${DAI_ADDRESS}` +
        "&topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"+
        "&topic1_2_opr=or"+
        `&topic1=0x000000000000000000000000${address.split('0x')[1]}`+
        `&topic2=0x000000000000000000000000${address.split('0x')[1]}`+
        "&apikey=N9GE9PGPPPC23YW85WJ6Y1FW2DRAXPEWQM"

        let response = await axios.get(url,{params:{cors:true}})
        if(response && response.data && response.data.result){
          let history = [];
          response.data.result.reverse().forEach(tx => {
            // if(tx.topics !=='' && tx.value !=="0"){
              let type = 'DAI';
              let from = tx.topics[1].replace('000000000000000000000000','');
              let to = tx.topics[2].replace('000000000000000000000000','');
              let value = (tx.data) / Math.pow(10,18);
              let timestamp = web3.utils.hexToNumber(tx.timeStamp);
              let color = to === address.toLowerCase() ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
              if(from === to) color = 'grey';
              history.push({height:tx.blockNumber,from,timestamp,type,to,value,color,txHash:tx.transactionHash})
            // }
          });
          deletePending(history,getState(),dispatch)

          dispatch({
            type: FETCHED_HISTORY,
            payload:{history,type:'dai',address}
          })
        }
      }
    }
    catch(err){
      console.log({err})
    }
  }
}
