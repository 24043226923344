export {setBTCWallet} from './btc';
export {setETHWallet} from './eth';
export {setDAIWallet} from './dai';
export {setUSDTWallet} from './usdt';
export {setNEOWallet} from './neo';
export {setWAVWallet} from './wav';
export {setLSKWallet} from './lsk';
export {setBSECWallet} from './bsec';
export {setNRGWallet} from './nrg';
export {setLEMBWallet} from './lemb';
export {setEMBWallet} from './emb';

export {removeAsset,removeWallet} from './helper';

export {getBTCAddress} from './btc';
export {getETHAddress} from './eth';
export {getEMBAddress} from './emb';
export {getLEMBAddress} from './lemb';
export {getNRGAddress} from './nrg';
export {getNEOAddress} from './neo';
export {getWAVAddress} from './wav';
export {getLSKAddress} from './lsk';
export {getDAIAddress} from './dai';
export {getUSDTAddress} from './usdt';
