import React, { Component } from 'react';

class TransferDetails extends Component {
  render() {
    return (
      <div>
        <img src={`./${this.props.chain.toLowerCase()}.png`} style={{height:'50px',float:'left'}} />
        <h4 className="black-text" style={{marginTop: '7px',marginLeft:'10px',display:'inline-block'}}> {this.props.settle ? 'Settlement' : 'Transfer'} Confirmation</h4>
        <table className="black-text transferDetails" style={{tableLayout:"fixed",fontSize:"1.5em",paddingBottom:'10px'}}>
          <thead>
            <tr>
              <th style={{width:'240px'}}>To</th>
              <th className='shorter'>Amount</th>
              <th style={{width:'80px'}} className='shorter'>Fee</th>
              <th className='shorter'>Total</th>
            </tr>
          </thead>
          <tbody style={{lineHeight:'30px',padding:'0px'}}>
            <tr>
              <td style={{overflow:'hidden',textOverflow:'ellipsis'}}>{this.props.to}</td>
              <td>{this.props.amount} {this.props.chain}</td>
              <td>{this.props.fee} {this.props.chain}</td>
              <td>{parseFloat(this.props.amount) + parseFloat(this.props.fee)} {this.props.chain}</td>
            </tr>
          </tbody>
        </table>
      </div>
		)
  }
}

export default TransferDetails;
