import React from 'react';

export default (field) => (
  <fieldset onClick={field.onclick} className="form-group" style={{border:'none',paddingTop:0,paddingBottom:0}}>
    <label>{field.label}</label>
    {field.label2 && <label style={{float:'right',marginTop:'2px'}}>{field.label2}</label>}
    <input
      style={{borderBottom:'1px solid #9e9e9e',color:'white'}}
      id={field._id ? field._id : ""}
      autoComplete="off"
      className="form-control inline"
      data-length={field.dataLength}
      maxLength={field.maxLength}
      {...field.input}
      type={field.type}
      placeholder={field.placeholder}
      step={field.type === 'number' ? 0.00000001 : 'any'}
      required={field.required? 'required' : ''}
      disabled={(field.disabled || field.val) ? 'disabled' : ''}
      value={field.val ? field.val : undefined}
      onChange={field.onChang ? field.onChang : null}
    />
  </fieldset>
);
