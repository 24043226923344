import React, { Component } from 'react';
import { connect } from 'react-redux';
import Title from '../../helper/title';
import {reduxForm,Field,SubmissionError } from 'redux-form';
import M from 'materialize-css/dist/js/materialize.min.js'
import renderOption from '../../helper/option';
import {fee} from '../../../config';
import BN from 'bn.js';
import renderInput from '../../helper/input';
import Modal from '../../helper/modal';
import {setAmount,setFee} from '../../../actions/ui/passport';
import {consolidateNRG,sendTxs,payNEO,payBTC,payETH,payLSK,payWAV,payNRG,payEMB,payDAI,payUSDT} from '../../../actions/transfers/set';
import {getNEOFee,getBTCFee,getETHFee,getLSKFee,getWAVFee,getNRGFee,getEMBFee,getDAIFee,getLEMBFee,getUSDTFee} from '../../../actions/transfers/fees';
import TransferDetails from '../../helper/transferDetails';
import Circle from '../../helper/circle';
import $ from "jquery";
import {COIN_FRACS, internalToHuman} from 'bc-sdk/dist/utils/coin'

class Transfers extends Component {
  constructor(){
    super();
    this.state = {view: 'nrg',view2:'nrg',to:'',txs:[]};
  }
  renderTxs(){
    if(this.state.txs.length > 0){
      let sum = 0;
      let txs = this.state.txs.map((tx)=>{
        let amount = new BN(Buffer.from(tx.getOutputsList()[0].getValue(),'base64').toString('hex'),16);
        let sum = internalToHuman(amount.add(fee), COIN_FRACS.NRG)
        amount = internalToHuman(amount, COIN_FRACS.NRG)
        return (
          <tr>
            <td style={{overflow:'hidden',textOverflow:'ellipsis'}}>{this.props.addressText}</td>
            <td>{amount} NRG</td>
            <td>{internalToHuman(fee,COIN_FRACS.NRG)} NRG</td>
            <td>{sum} NRG</td>
          </tr>
        )
      })
      return (
        <div>
          <div className='col s12' id='bottom' style={{overflowY:'scroll',maxHeight:'300px'}}>
            <table className="black-text transferDetails" style={{tableLayout:"fixed",fontSize:"1.5em",paddingBottom:'10px',marginTop:'20px'}}>
              <thead>
                <tr>
                  <th  style={{width:'240px'}}>To</th>
                  <th className='shorter'>Amount</th>
                  <th  style={{width:'80px'}} className='shorter'>Fee</th>
                  <th className='shorter'>Total</th>
                </tr>
              </thead>
              <tbody style={{lineHeight:'30px',padding:'0px'}}>
                {txs}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
    else {
      return (<div></div>)
    }
    // <tbody style={{lineHeight:'30px',padding:'0px'}}>

    // </tbody>
    // internalToHuman(Buffer.from(v, 'base64'), COIN_FRACS.NRG)
  }
  renderModal(){
    let component = (<TransferDetails amount={this.props.amount} to={this.state.to} from={this.props.addressText} fee={this.props.fee} chain={this.props.view.toUpperCase()} />)
    let chain = this.props.view.toUpperCase();
    return (
      <Modal id='modalTransfer' style={{width:'700px'}} classes='modal modalOrders black-text' chain={chain} noClose={true} component={component} isOrder={false} decryption={true} buttonText={"Confirm"} buttonAction={this.submitOperation.bind(this)} />
    )
  }

  submitConsolidate(){
    let _this = this;
    if(this.state.txs.length == 0){
      let password = $("#passwordConsolidation").val();
      this.props.consolidateNRG({password,address:this.props.addressText},(err,txs)=>{
        if(!err) {
          $("#passwordConsolidation")[0].value = '';
          _this.setState({txs})
        }
        else M.toast({html: err,classes:"red",displayLength:3000});
      })
    }
    else {
      this.props.sendTxs({txs:this.state.txs,address:this.props.addressText},(err,txs)=>{
        if(!err) {
          _this.setState({txs:[]})
          var instance = M.Modal.getInstance(document.querySelectorAll('#modalConsolidation')[0]);
          if(instance) instance.close();
          setTimeout(()=>{
            M.toast({html: 'Transactions Submitted!',classes: "green",displayLength:3000});
          },200);
        }
        else {
          M.toast({html: err,classes:"red",displayLength:3000});
        }
      })
    }
  }
  closeConsolidate(){
    this.setState({txs:[]})
  }
  renderConsolidate(){
    let component = (
      <div >
        <img src={`/nrg.png`} style={{height:'50px',float:'left'}} />
        <h4 className="black-text" style={{marginTop: '7px',marginLeft:'10px',display:'inline-block'}}> Consolidation Confirmation</h4>
        <div className='col s12'>
          <div className='col s6' style={{textAlign:'center',borderRight:'1px solid black'}}>
            <h6 style={{fontSize:'1rem',padding:'5px',opacity: this.state.txs.length == 0 ? 1 : 0.5}}>
              Step 1. Populate Transactions
            </h6>
          </div>
          <div className='col s6' style={{textAlign:'center'}}>
            <h6 style={{fontSize:'1rem',padding:'5px',opacity: this.state.txs.length == 0 ? 0.5 : 1}}>
              Step 2. Send & Confirm
            </h6>
          </div>
          {this.renderTxs()}
        </div>
      </div>
    );
    return (
      <Modal id='modalConsolidation' style={{width:'700px'}} passwordId='passwordConsolidation' classes='modal modalOrders black-text' chain={'NRG'} noClose={true} component={component} isOrder={false} decryption={this.state.txs.length === 0} buttonText={this.state.txs.length > 0 ? "Confirm" : 'Populate'} closeAction={this.closeConsolidate.bind(this)} buttonAction={this.submitConsolidate.bind(this)} />
    )
  }
  componentDidMount(){
    $('#to').focus();
  }
  componentWillReceiveProps(newProps){
    if(this.props.view !==newProps.view){
      if(newProps.view === 'btc' || newProps.view == 'nrg') {
        this.props[`get${newProps.view.replace('-','').toUpperCase()}Fee`](newProps.amount,newProps.addressText);
      }
      else {
        this.props[`get${newProps.view.replace('-','').toUpperCase()}Fee`](newProps.amount);
      }
    }
  }
  onChangeAmount(e){
    this.props.setAmount(e.target.value,()=>{});
    if(this.props.view === 'btc' || this.props.view == 'nrg') {
      this.props[`get${this.props.view.replace('-','').toUpperCase()}Fee`](e.target.value,this.props.addressText);
    }
    else {
      this.props[`get${this.props.view.replace('-','').toUpperCase()}Fee`](e.target.value);
    }
  }
  changeAmount(percent){
    let newAmount = parseFloat(this.props.availableAmount * percent);
    this.props.setAmount(newAmount,()=>{})
    if(this.props.view === 'btc' || this.props.view == 'nrg') {
      this.props[`get${this.props.view.replace('-','').toUpperCase()}Fee`](newAmount,this.props.addressText);
    }
    else {
      this.props[`get${this.props.view.replace('-','').toUpperCase()}Fee`](newAmount);
    }
  }
  changeAmount100(percent){
    let newAmount = parseFloat(this.props.availableAmount * percent);
    let fun = ()=>{
      let val = newAmount - this.props.fee
      $('#amount')[0].value = val;
      this.props.setAmount(val,()=>{})
    }
    if(this.props.view === 'btc' || this.props.view == 'nrg') {
      this.props[`get${this.props.view.replace('-','').toUpperCase()}Fee`](newAmount,this.props.addressText,fun);
    }
    else {
      this.props[`get${this.props.view.replace('-','').toUpperCase()}Fee`](newAmount,fun);
    }
  }
  onChangeTo(e){
    this.setState({to:e.target.value});
  }
  submitOperation(){
    let password = $("#password").val();
    this.props[`pay${this.props.view.toUpperCase()}`]({password,address:this.props.addressText,amount:this.props.amount,to:this.state.to},(err,tx)=>{
      if(!err) {
        $('#amount')[0].value = '';
        $('#to')[0].value = '';
        $("#password")[0].value = '';
        this.props.setFee('',()=>{});
        this.props.setAmount('',()=>{})
        var instance = M.Modal.getInstance(document.querySelectorAll('#modalTransfer')[0]);
        if(instance) instance.close();
        setTimeout(()=>{
          M.toast({html: 'Transaction Submitted!',classes: "green",displayLength:3000});
        },200);
      }
      else {
        M.toast({html: err,classes:"red",displayLength:3000});
      }
    })
  }
  openConsolidation() {
    setTimeout(()=>{
      var instance = M.Modal.getInstance(document.querySelectorAll('#modalConsolidation')[0]);
      instance.open();
      setTimeout(()=>{
        if($("#passwordConsolidation")) $("#passwordConsolidation").focus()
      },100)
    },100);
  }
  handleFormSubmit() {
    setTimeout(()=>{
      var instance = M.Modal.getInstance(document.querySelectorAll('#modalTransfer')[0]);
      instance.open();
      setTimeout(()=>{
        if($("#password")) $("#password").focus()
      },100)
    },100);
  }
  copyFrom(){
    var copyText = document.getElementById("from");

    var textField = document.createElement('textarea')
    textField.innerText =  copyText.value
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    M.toast({html: 'Copied Address',classes:"black",displayLength:3000});
  }
  renderForm(){
    const { handleSubmit } = this.props;
    let disabled = parseFloat(this.props.availableAmount) < parseFloat(this.props.amount)
    let k = ['USDT','DAI','EMB','L-EMB'].includes(this.props.view.toUpperCase()) ? 'ETH' : this.props.view.toUpperCase()

    let label2 = `Tx Fee: ${this.props.fee} ${k}`;
    return (
      <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} style={{marginTop:'10px',zoom:1.1}} autoComplete='off'>
        <div className="row" style={{paddingTop:'5px'}}>
          <i className="material-icons searchIcon" onClick={this.copyFrom.bind(this)} style={{marginLeft:'-10px',marginTop:'32px',cursor:'pointer'}}>content_copy</i>
          <Field name="from" _id='from' onclick={this.copyFrom.bind(this)} component={renderInput} type="text" label={`From:`} disabled={true} val={this.props.addressText}  />
          <Field name="to" _id='to' onChang={this.onChangeTo.bind(this)} component={renderInput} step={'any'} type="text" label={`Recipient:`} required={true}/>
          <Field name="amount" _id='amount' onChang={this.onChangeAmount.bind(this)} component={renderInput} step={'any'} type="number" label={`Amount:`} label2={label2} required={true}/>
          {this.renderBalance()}
      </div>
        <div className="row center" style={{paddingTop:'10px'}}>
          <button className="btn black-text"  disabled={disabled} style={{backgroundColor:'white',fontSize:'15px'}} action="submit"> Send <b>{this.props.view}</b> <i className='material-icons' style={{float:'right',marginLeft:'5px'}}>send</i></button>
        </div>
      </form>
    )
  }
  renderBalance(){
    return (
      <div className='row' style={{paddingRight:'0.75em',paddingLeft:'0.75em',marginRight: "2px",marginLeft:'2px'}}>
        <div id='placeOrderBal' className='col s6' style={{padding:0}}>
          <p style={{margin:0}}>Balance: {this.props.availableAmount} {this.props.view.toUpperCase()} <br/>
            {this.props.view && !['emb','l-emb','nrg'].includes(this.props.view.toLowerCase()) ?
              <span style={{color:'white',opacity:0.75,position:'absolute'}}> ({Math.round(100*this.props.availableAmount*this.props.price[this.props.view.toLowerCase()])/100} USD)</span>
              : <span style={{color:'black',position:'absolute'}}>|</span>
            }
          </p>
        </div>
        <div id='placeOrderNums' className='col s6' style={{padding:0,marginTop:'-2px'}}>
          <div className='col s3'>
            <p style={{margin:0}} onClick={this.changeAmount.bind(this,0.1)}>10%</p>
          </div>
          <div className='col s3'>
            <p style={{margin:0}} onClick={this.changeAmount.bind(this,0.25)}>25%</p>
          </div>
          <div className='col s3'>
            <p style={{margin:0}} onClick={this.changeAmount.bind(this,0.5)}>50%</p>
          </div>
          <div className='col s3'>
            <p style={{margin:0}} className='tooltipped' data-position="bottom" data-tooltip={`Full Balance - ${this.props.view.toUpperCase()} Tx Fee`} onClick={this.changeAmount100.bind(this,1)}>Max</p>
          </div>
        </div>
      </div>
    )
  }
  click(e){
    e.stopPropagation()
    return
  }
  render() {
    let src = `/${this.props.view.toLowerCase()}.png`;

    return (
      <div className="col s12 transfers" style={{}}>
        <Title text='transfer' />

        <div className='col s3 center' style={{padding:'0',marginTop:'40px'}} onClick={this.click.bind(this)}>
          <h5 className='white-text' style={{fontFamily:'Gilmer',marginBottom:'0'}}>{this.props.view.toUpperCase()}</h5>
          <Circle view={this.props.view} address={this.props.addressText} div='transferdiv' noLegend={true} big={true} />
        </div>

        <div className='col s6' style={{padding:'0'}}>
          {this.renderForm()}
          <br/>
        </div>

        <div className='col s3' style={{padding:'0',marginTop:'140px',textAlign:'center'}}>
          {this.props.view.toUpperCase() === 'NRG' &&
            <a className="center hoverA" onClick={this.openConsolidation.bind(this)}>Consolidate UTXOs</a>
            }
        </div>
        {this.renderConsolidate()}
        {this.renderModal()}
      </div>
		)
  }
}

Transfers = reduxForm({
  form: 'transfers',  // name of the form
})(Transfers);

function mapStateToProps(state,props) {
  let asset = state.ui_passport.asset_view.toLowerCase();
  let address = state.ui_passport.address_view;
  return {
    price: state.price,
    fee: state.passport.fee,
    amount: state.ui_passport.amount,
    view: asset,
    addressText: address,
    availableAmount: state.passport[asset] && state.passport[asset][address] ? state.passport[asset][address] : 0,
  };
}


export default connect(mapStateToProps, {setAmount,setFee, sendTxs,
  getNEOFee,getBTCFee,getETHFee,getLSKFee,getWAVFee,getNRGFee,getEMBFee,getDAIFee,getLEMBFee,getUSDTFee, consolidateNRG,
  payNEO,payBTC,payETH,payLSK,payWAV,payNRG,payEMB,payDAI,payUSDT
})(Transfers);
