import {FETCHED_BALANCE} from '../../types';
import {EMB} from '../../../config'

export function getEMBBalanceDispatch(){
  return function(dispatch,getState){
    getEMBBalance(dispatch,getState())
  }
}

export async function getEMBBalance(dispatch,state){
  for(let address of Object.keys(state.passport.wallet.emb)){
    EMB.methods.balanceOf(address.toLowerCase()).call().then((result)=>{
      dispatch({
        type: FETCHED_BALANCE,
        payload: {balance:result/Math.pow(10,8),asset:'emb', address}
      })
    })
  }
}
