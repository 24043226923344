const WavesAPI = require('@waves/waves-api');

export function genWAVWallet(done){
  return function(dispatch,getState) {
    try {
      const Waves = WavesAPI.create(WavesAPI.MAINNET_CONFIG);
      const seed = Waves.Seed.create()
      done({address:seed.address,privateKey:seed.phrase,type:'WAV'})
    }
    catch(err){
      done(err);
    }
  }
}
