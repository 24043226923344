import React from 'react';

// 404
export default (field) => {

  let options = Object.keys(field.data).map((key)=>{
    return (
      <option id={field._id + key} value={key}>{field.data[key]}</option>
    )
  });

  options.unshift(
    <option value={0}>Choose Option</option>)

  return(
    <div className="">
    <fieldset className="form-group" style={{border:'none'}}>

      <label>{field.label}</label>
      <select onchange={field.onChang ? field.onChang : null} onChange={field.onChang ? field.onChang : null} id={field._id ? field._id : null} {...field.input} >{options}</select>
      {field.meta.touched && field.meta.error &&
        <span style={{color:'red'}}>
          {field.meta.error}
        </span>
      }
    </fieldset>
    </div>
  )
}
