import {getWallet} from '../../helpers/wallet';
import {decrypt} from '../../helpers/crypto';
import {addPending} from './addPending';
import Neon, { wallet as NeonWallet, api as NeonAPI} from "@cityofzion/neon-js";
import axios from 'axios';
import {SET_LOADING} from '../../types';

export function payNEO({password,amount,address,to},done){
	return function(dispatch,getState) {
    try {
			dispatch({type:SET_LOADING,payload:true})

			let {privateKey} = getWallet(getState,address,'neo',password)
			const intent = NeonAPI.makeIntent({NEO:amount},to)
	    const apiProvider = new NeonAPI.neoscan.instance('MainNet');
	    const config = {
	      api: apiProvider, // The API Provider that we rely on for balance and rpc information
	      account: privateKey, // The sending Account
	      intents: intent // Our sending intents
	    };
	    Neon.sendAsset(config).then(config => {
	      addPending(dispatch,getState(),{hash:config.tx.hash,chain:'NEO',to,from:address,amount})
				dispatch({type:SET_LOADING,payload:false})
				done(null,config.tx.hash)
	    })
	    .catch(config => {
	      console.log(config);
				dispatch({type:SET_LOADING,payload:false})
	      done(config)
	    });
    }
    catch(err){
			dispatch({type:SET_LOADING,payload:false})
      done(err);
    }
	}
}
