import {
  SET_SETTINGS
} from '../actions/types';

import {settings} from '../config'

let settingsLocal = JSON.parse(window.localStorage.getItem('settings'));

let initialState =  {
  settings : settingsLocal ? settingsLocal : settings,
};

export default function(state: Object = initialState, action) {
  switch(action.type) {
    case SET_SETTINGS:
      return {...state,settings:action.payload};
    default:
      return state;
  }
}
