import React, { Component } from 'react';

class Title extends Component {
  render(){
    return (
      <div className='row' style={{padding:0,margin:0}}>
        <div className='col s12' style={{}}>
          <h4 className='center white-text' style={{marginTop:'15px',fontSize:'1.8rem'}} >{this.props.text}</h4>
          <hr style={{margin:0,borderColor:'grey'}}/>
        </div>
      </div>
    )
  }
}

export default Title
