import {FETCHED_NRG, FETCHED_SPENDABLE_OUTPOINTS, FETCHED_BALANCE} from '../../types';
import { createWallet} from '../../helpers/bc'
import callBC from '../../helpers/bc'

import {
  humanToInternalAsBN,
  COIN_FRACS,
  internalToBN,
  internalBNToHuman,
  Currency,
  CurrencyInfo
} from 'bc-sdk/dist/utils/coin'
import {
  bytesToInternalBN,
  createOutPoint,
  createTransactionInput,
  createTransactionOutput,
  convertProtoBufSerializedBytesToBuffer
} from 'bc-sdk/dist/utils/protoUtil'

const BN = require('bn.js')

export function getNRGBalanceDispatch(){
  return function(dispatch,getState){
    getNRGBalance(dispatch,getState())
  }
}

export async function getSpendableOutpoints(dispatch,state){
  console.log('utxo retrieval')
  const wallet = createWallet(state.settings.settings)
  if(state.passport.wallet && state.passport.wallet.nrg){
    for(let address of Object.keys(state.passport.wallet.nrg)){
      let data = await callBC(state.settings.settings,'getUTXOLength',['nrg_transfer',address.toLowerCase()])
      let outpoints = [];
      if(data && data.result && data.result.length > 0){
        let final = data.result.length;
        let from = 0;
        let to = final < 1000 ? final : 1000;
        let search = true;
        while(search){
          data = await callBC(state.settings.settings,'getSpendableOutpoints',[address.toLowerCase(),from,to])
          if(data && data.result && data.result.spendableOutpointsList){
            let spendables = data.result.spendableOutpointsList;
            if(outpoints.length > 0 && spendables.length > 0 && `${outpoints[outpoints.length-1].outpoint.hash} + ${outpoints[outpoints.length-1].outpoint.index}` === `${spendables[spendables.length-1].outpoint.hash} + ${spendables[spendables.length-1].outpoint.index}`){
              search = false;
            }
            else {
              outpoints = outpoints.concat(data.result.spendableOutpointsList)
            }
          }
          if(to === final) search = false;
          from = to + 1;
          to = to + 1000 > final ? final : to + 1000;
        }
      }
      console.log({outpoints})
      if(outpoints){
        outpoints = outpoints.sort((a,b)=>{
          return internalToBN(convertProtoBufSerializedBytesToBuffer(a.outpoint.value), COIN_FRACS.BOSON).gt(
            internalToBN(convertProtoBufSerializedBytesToBuffer(b.outpoint.value), COIN_FRACS.BOSON)
          ) ? -1 : 1
        })
        dispatch({
          type:FETCHED_SPENDABLE_OUTPOINTS,
          payload:{outpoints,address}
        })
      }
    }
  }
  return
}

export async function getNRGBalance(dispatch,state){
  try {
    const wallet = createWallet(state.settings.settings)
    for(let address of Object.keys(state.passport.wallet.nrg)){
      let balance = await wallet.getBalance(address.toLowerCase())
      if(balance){
        dispatch({
          type:FETCHED_BALANCE,
          payload: {address,balance:parseFloat(balance.confirmed), asset:'nrg'}
        });
        // dispatch({
        //   type: FETCHED_NRG,
        //   payload: {address,nrg:parseFloat(balance.confirmed),unlockable:parseFloat(balance.unlockable),nrg_collateralized:parseFloat(balance.collateralized)}
        // });
      }
    }
  }
  catch(err){
    console.log({err})
  }
}
