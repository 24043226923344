import { combineReducers } from 'redux';
import passport from './passport';
import price from './price';

import pending from './pending';
import settings from './settings';
import {reducer as socket} from './socket';
import transfer_history from './transfer_history';
import ui_passport from './ui_passport';

const rootReducer = combineReducers({
  // form,
  price,
  passport,
  pending,
  settings,
  socket,
  transfer_history,
  ui_passport
});

export default rootReducer;
