import {FETCHED_FEE} from '../../types';

export function getNEOFee(amount,done){
	return function(dispatch,getState) {
    dispatch({
      type:FETCHED_FEE,
      payload: 0
    })
		if(done) done();
  }
}
