import {FETCHED_HISTORY,} from '../../types';
import {deletePending} from '../../ui/passport';

const WavesAPI = require('@waves/waves-api');

export function getWAVTransfers(done){
  return async function(dispatch,getState){
    try {
      const Waves = WavesAPI.create(WavesAPI.MAINNET_CONFIG);
      for(let address of Object.keys(getState().passport.wallet.wav)){
        let txs = await Waves.API.Node.transactions.getList(address)

        let history = [];
        txs.forEach(tx => {
          let timestamp = tx.timestamp / 1000;
          let type = "WAV";
          let from = tx.sender;
          let to = tx.recipient;
          let color = to === address ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
          let value = tx.amount / Math.pow(10,8)
          if(value && to && tx.assetId === null){
            history.push({height:tx.height,from,timestamp,type,to,value,color,txHash:tx.id})
          }
        })

        deletePending(history,getState(),dispatch)

        dispatch({
          type: FETCHED_HISTORY,
          payload:{history,type:'wav',address}
        })
      }
    }
    catch(err){
      console.log({err})
    }
  }
}
