import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css/dist/js/materialize.min.js'
import Modal from '../helper/modal';
import {txURL} from '../../config';

let modalId = 'modalPending'

let overflow = "hidden";
let textOverflow = 'ellipsis';

class PendingTransactions extends Component {
  nrgURL(hash){
    return 'http://'+this.props.settings.hostname + ':' + this.props.settings.port + '/#/tx/'+hash
  }
  pendingTransactionsModal(){
    let title = `Pending Transactions`
    let component = this.pendingTable()
    return (
      <Modal id={modalId} classes='modal' title={title} noClose={true} component={component}/>
    )
  }
  pendingTable(){
    let items = this.props.transactions.map((tx) => {
      if(tx.type === "Transfer"){
        return (<tr>
          <td style={{lineHeight:'20px'}}>{tx.type}</td>
          <td>{tx.chain}</td>
          <td style={{overflow,textOverflow}}>
            <a href={tx.chain.toUpperCase() === 'NRG' ? this.nrgURL(tx.hash) : txURL[tx.chain]+tx.hash} style={{padding:0,fontSize:'10px',color:'black',overflow,textOverflow,textDecoration:'underline'}} rel="noopener noreferrer" target="_blank">{tx.hash}</a>
          </td>
          <td style={{overflow,textOverflow}}>{tx.from}</td>
          <td style={{overflow,textOverflow}}>{tx.to}</td>
          <td style={{overflow,textOverflow}}>{tx.amount} {tx.chain}</td>
        </tr>)
      }
      else {
        return(
        <tr>
          <td style={{overflow,textOverflow,lineHeight:'20px'}}>{tx.type}</td>
          <td style={{overflow,textOverflow}}>{tx.chain}</td>
          <td style={{overflow,textOverflow}}>{tx.hash}</td>
          <td style={{overflow,textOverflow}}>{this.props.address}</td>
          <td style={{overflow,textOverflow}}>Tx Pool</td>
          <td style={{overflow,textOverflow}}>{tx.amount}</td>
        </tr>)
      }
    })
    return (
      <div className="" style={{height:'350px',overflowY:'scroll'}}>
        <table className="black-text" style={{tableLayout:"fixed",fontSize:"0.85em"}}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Asset</th>
              <th style={{width:'200px'}}>Tranaction Hash</th>
              <th>From</th>
              <th>To</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody style={{lineHeight:'30px',padding:'0px'}}>
            {items}
          </tbody>
        </table>
      </div>
    )
  }
  openModal(){
    var instance = M.Modal.getInstance(document.querySelectorAll(`#${modalId}`)[0]);
    instance.open();
  }
  render() {
    let num = this.props.pendingTransactions
    if(num !==0) {
      return (
        <li>
          <a className='pending rightLink' onClick={this.openModal.bind(this)}> PENDING TX ({num}) </a>
          {this.pendingTransactionsModal()}
        </li>
      )
    }
    else return null
  }
}

function mapStateToProps(state,props) {

  let pendingTransactions = state.pending.transactions;
  let transactions = pendingTransactions ? Object.keys(pendingTransactions).map(key=>pendingTransactions[key]) : [];
  pendingTransactions = transactions.length > 0 ? Object.keys(pendingTransactions).length : 0;

  return {
    settings: state.settings.settings,
    address: state.passport.wallet && state.passport.wallet.emb ? state.passport.wallet.emb.address : '',
    transactions,
    pendingTransactions,
  };
}

//
export default connect(mapStateToProps, {})(PendingTransactions);
