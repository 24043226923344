import {saveWallet} from './helper';
import {encrypt} from '../../helpers/crypto'
var EthWallet = require('ethereumjs-wallet');
var EthUtil = require('ethereumjs-util');

export function setDAIWallet(walletKey,password,done){
  return function(dispatch,getState) {
    if(walletKey.substring(0,2) !== "0x") walletKey = "0x" + walletKey;
    try {
      const privateKeyBuffer = EthUtil.toBuffer(walletKey);
      const ethwallet = EthWallet.fromPrivateKey(privateKeyBuffer);
      const address = ethwallet.getAddressString();
      let enc = encrypt(walletKey,password);
      saveWallet(getState(),'dai',address,enc,dispatch)
      done(null)
    }
    catch(err){
      done(err);
    }
  }
}

export function getDAIAddress(walletKey){
  return function(dispatch,getState) {
    try {
      if(walletKey.substring(0,2) !== "0x") walletKey = "0x" + walletKey;
      const privateKeyBuffer = EthUtil.toBuffer(walletKey);
      const ethwallet = EthWallet.fromPrivateKey(privateKeyBuffer);
      const address = ethwallet.getAddressString();
      return address;
    }
    catch(err){
      return;
    }
  }
}
