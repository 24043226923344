import {sendTransaction} from '../../helpers/bitcoin';
import {getWallet} from '../../helpers/wallet';
import {addPending} from './addPending';
import {SET_LOADING} from '../../types';


var bitcoin = require('bitcoinjs-lib');

async function btcSign(dispatch,getState,{password,address,amount,to},done){
  try {
    let {privateKey} = getWallet(getState,address,'btc',password)
    let signed = await sendTransaction({
      from: address,
      to,
      privKeyWIF: privateKey,
      btc: amount,
      dryrun: false,
      network: "mainnet"
    });
    if(signed){
      done(null,signed.msg);
    }
    else {
      done('Could Not Submit Transaction')
    }
  }
  catch(err){
    console.log({err})
    done(err)
  }
}

export function payBTC({password,amount,address,to},done){
	return function(dispatch,getState) {
    dispatch({type:SET_LOADING,payload:true})
    btcSign(dispatch,getState,{password,address,amount,to},(err,signed)=>{
      if(signed){
        var txid = bitcoin.Transaction.fromHex(signed).getId();
        addPending(dispatch,getState(),{hash:txid,chain:'BTC',to,from:address,amount})
      }
      dispatch({type:SET_LOADING,payload:false})
      done(err,signed);
    })
	}
}
