import {saveWallet} from './helper';
import {encrypt} from '../../helpers/crypto'
import {wallet as NeonWallet} from "@cityofzion/neon-js";

export function setNEOWallet(walletKey,password,done){
  return function(dispatch,getState) {
    try {
      let account = new NeonWallet.Account(walletKey);
      let address = account.address;
      let enc = encrypt(account.privateKey,password);
      saveWallet(getState(),'neo',address,enc,dispatch)
      done(null)
    }
    catch(err){
      console.log({err})
      done(err);
    }
  }
}

export function getNEOAddress(walletKey){
  return function(dispatch,getState) {
    try {
      let account = new NeonWallet.Account(walletKey);
      let address = account.address;
      return address;
    }
    catch(err){
      return;
    }
  }
}
