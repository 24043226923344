import SocketIO from 'socket.io-client'
import {
 SOCKET_CREATED,
 SOCKET_CONNECTED,
 SOCKET_DISCONNECTED,
 BLOCKS_MINED_ADD,
 RETRIEVING_DATA,
 FETCHED_NRG_PRICE,
 FETCHED_EMB_PRICE,
 FETCHED_LEMB_PRICE,

 FETCHED_DAI_PRICE,
 FETCHED_BTC_PRICE,
 FETCHED_ETH_PRICE,
 FETCHED_NEO_PRICE,
 FETCHED_WAV_PRICE,
 FETCHED_LSK_PRICE,
 FETCHED_USDT_PRICE,
 WALLET_SET,
 NO_RPC
} from '../actions/types';

import {getBalances} from '../actions/passport/get'
import genAllWallet from '../actions/passport/generate/all';
import {getSpendableOutpoints} from '../actions/passport/get/nrg';
import {getAllTransfers} from '../actions/transfers/get';
import {checkPending} from '../actions/transfers/set/addPending';
import callBC from '../actions/helpers/bc';
import axios from 'axios'
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const init = (location, dispatch, store) => {
  //backwords compatability
  let wallet = JSON.parse(window.localStorage.getItem('bc-wallet'))
  if(wallet && wallet.emb && wallet.emb.address){
    let newWallet = {};
    Object.keys(wallet).map((asset)=>{
      newWallet[asset] = {}
      newWallet[asset][wallet[asset].address] = {
        privateKey: wallet[asset].privateKey,
        salt: wallet[asset].salt,
        iv: wallet[asset].iv,
      }
    })
    dispatch({type:WALLET_SET,payload:newWallet})
  }

  const hostname = location.hostname.replace(/https?:\/\//, '')
  let url = location.port !=='' ? `ws://${hostname}:${location.port}` : `wss://${hostname}`;
  if(location.hostname.includes('ngrok')) url = `wss://${hostname}`;
  const socket = SocketIO(url, {path: '/ws',rejectUnauthorized: false,transports:['websocket']});

  dispatch({type:SOCKET_CREATED,payload:{socket,location}})

  setInterval( async ()=> {
    if(!store.getState().socket.connected || store.getState().socket.bcHeight === 'SYNCING') {
      let data = await callBC(store.getState().settings.settings,'getLatestBlock',[])
      getAllTransfers(dispatch,store.getState)
      getBalances(dispatch,store.getState())
      if(data.result && data.result.height) {
        updateOrdersState(data.result,dispatch,store)
      }
    }
  }, 5000)

  socket.on('connect', () => {
    dispatch({type: SOCKET_CONNECTED,payload:socket})

    callBC(store.getState().settings.settings,'getSyncStatus',[]).then((data)=>{
      if(data && data.result && data.result.status) {
        dispatch({type:NO_RPC})
      }
    });

    socket.on('disconnect', ()=> dispatch({type: SOCKET_DISCONNECTED}))

    //get latest block when we start up
    socket.emit('block.get',{id:'latest'});

    const events = ['block.set','block.mined','block.new','block.latest']
    events.forEach((event) => {
      socket.on(event, async (data) => {
        if(data) updateOrdersState(data,dispatch,store)
      })
    })
  });
}

async function convertBalance(tokenName){
  let toSymbol = "USD";
  var token = tokenName
  let url = "https://api.coingecko.com/api/v3/simple/price?ids=" + token + "&vs_currencies=" + toSymbol + "&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false"
  let call =  await axios.get(url,{params:{cors:true}});
  return call.data[token] ? call.data[token].usd : 0
}

//on each new block, get balances and orders
const updateOrdersState = async (data,dispatch,store) => {
  if(data)dispatch({type:BLOCKS_MINED_ADD,payload:data});
  if(store.getState().socket.retrieving === false ||
  store.getState().ui_passport.loading === true
){
    dispatch({type:RETRIEVING_DATA,payload:true})

    await getBalances(dispatch,store.getState())
    await getSpendableOutpoints(dispatch,store.getState())
    await getAllTransfers(dispatch,store.getState)
    await checkPending(dispatch,store.getState())
    dispatch({type:RETRIEVING_DATA,payload:false})
  }
}



export const initPrices = async (dispatch) => {
  let map = {'bitcoin': FETCHED_BTC_PRICE, 'ethereum':FETCHED_ETH_PRICE, 'neo': FETCHED_NEO_PRICE,'waves':FETCHED_WAV_PRICE,'lisk':FETCHED_LSK_PRICE,'dai':FETCHED_DAI_PRICE, 'tether':FETCHED_USDT_PRICE}
  Object.keys(map).map(async (key)=>{
    let price = await convertBalance(key)
    dispatch({type:map[key],payload:price})
  })
}


const initialState = {
 latestBlock: null,
 location: {},
 timestamp: 0,
 bcHeight: "SYNCING",
 retrieving: false,
 connected: false,
 no_rpc: true,
 socket: null
}

export const reducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case BLOCKS_MINED_ADD:
      return { ...state, bcHeight: action.payload.height,timestamp:action.payload.timestamp,latestBlock:action.payload};

    case SOCKET_CREATED:
      // console.log(action.payload);
      return { ...state, socket:action.payload.socket,location:action.payload.location}

    case SOCKET_CONNECTED:
      return { ...state, connected: true}

    case SOCKET_DISCONNECTED:
      return { ...state, connected: false,socket:state.socket }

    case RETRIEVING_DATA:
      return { ...state, retrieving: action.payload}

    case NO_RPC:
          return {...state, no_rpc:false}
    default:
      return state
  }
}
