import {
  FETCHED_NRG_PRICE,
  FETCHED_EMB_PRICE,
  FETCHED_LEMB_PRICE,

  FETCHED_DAI_PRICE,
  FETCHED_BTC_PRICE,
  FETCHED_ETH_PRICE,
  FETCHED_NEO_PRICE,
  FETCHED_WAV_PRICE,
  FETCHED_LSK_PRICE,
  FETCHED_USDT_PRICE,
} from '../actions/types';

let initialState = {
  btc: 0,
  neo: 0,
  wav: 0,
  lsk: 0,
  eth: 0,
  emb: 0,
  'l-emb':0,
  usdt: 1,
  dai: 1,
  nrg: 0,
};

export default function(state: Object = initialState, action) {
  switch (action.type) {
    case FETCHED_NRG_PRICE:
      return { ...state, nrg: action.payload}
    case FETCHED_BTC_PRICE:
      return { ...state, btc: action.payload};
    case FETCHED_NEO_PRICE:
      return { ...state, neo: action.payload};
    case FETCHED_WAV_PRICE:
      return { ...state, wav: action.payload};
    case FETCHED_LSK_PRICE:
      return { ...state, lsk: action.payload};
    case FETCHED_DAI_PRICE:
      return { ...state, dai: action.payload};
    case FETCHED_ETH_PRICE:
      return { ...state, eth: action.payload};
    case FETCHED_EMB_PRICE:
      return { ...state, emb: action.payload};
    case FETCHED_USDT_PRICE:
      return { ...state, emb: action.payload};
    case FETCHED_LEMB_PRICE:
      return { ...state, 'l-emb': action.payload};
    default:
      return state;
  }
}
