import {SET_PENDING_TRANSACTIONS,OVERRIDE_PENDING_TRANSACTIONS} from '../../types';
import callBC from '../../helpers/bc.js';

export function addPending(dispatch,state,{hash,amount,chain,from,to}){
  let changed = false;
  dispatch({
    type:SET_PENDING_TRANSACTIONS,
    payload: {hash,type:"Transfer",chain,to,from,amount,time:Date.now()}
  })
}

export async function checkPending(dispatch,state){
  let pendingTransactions = state.pending.transactions;
  if(pendingTransactions){
    Object.keys(pendingTransactions).map(async (hash)=>{
      if(pendingTransactions[hash].chain == 'NRG'){
        let data = await callBC(state.settings.settings,'getBlockByTx',[hash])
        if(data && data.result && data.result.hash){
          delete pendingTransactions[hash]
        }
      }
      if(pendingTransactions[hash] && pendingTransactions[hash].time && Date.now() - pendingTransactions[hash].time > 120 * 60 * 1000){
        delete pendingTransactions[hash]
      }
      if(pendingTransactions[hash] && !pendingTransactions[hash].time){
        delete pendingTransactions[hash]
      }
    })
  }
  dispatch({
    type:OVERRIDE_PENDING_TRANSACTIONS,
    payload: pendingTransactions
  });
  return
}
