import {
  SET_PASSPORT_FEE,
  SET_PASSPORT_AMOUNT,
  SET_PASSPORT_ASSET_VIEW,
  OVERRIDE_PENDING_TRANSACTIONS
} from '../types';


export function deletePending(orders,state,dispatch){
  let pendingTransactions = state.pending.transactions;
  let latestBlock = state.socket.latestBlock;
  if(pendingTransactions) {
    orders.filter(o=>!o.dontRemove).forEach((o)=>{
      delete pendingTransactions[o.txHash];
    });
    dispatch({
      type:OVERRIDE_PENDING_TRANSACTIONS,
      payload: pendingTransactions
    });
  }
}

export function setAssetView(view,address){
  return function(dispatch) {
    dispatch({
      type: SET_PASSPORT_ASSET_VIEW,
      payload:{view,address}
    });
  }
}

export function setAmount(amount) {
  return function(dispatch) {
    dispatch({
      type: SET_PASSPORT_AMOUNT,
      payload:amount
    });
  }
}

export function setFee(fee){
  return function(dispatch) {
    dispatch({
      type: SET_PASSPORT_FEE,
      payload:fee
    });
  }
}

// if(o.type && latestBlock && latestBlock.blockchainHeaders && latestBlock.blockchainHeaders[`${o.type.toLowerCase()}List`] && latestBlock.blockchainHeaders[`${o.type.toLowerCase()}List`][latestBlock.blockchainHeaders[`${o.type.toLowerCase()}List`].length -1] && o.height > latestBlock.blockchainHeaders[`${o.type.toLowerCase()}List`][latestBlock.blockchainHeaders[`${o.type.toLowerCase()}List`].length -1].height){
  // console.log(latestBlock.blockchainHeaders[`${o.type.toLowerCase()}List`][latestBlock.blockchainHeaders[`${o.type.toLowerCase()}List`].length -1].height)
// }
// else {
