import React, { Component } from 'react';
import { connect } from 'react-redux';
import {multiverseUrl,blockURL} from '../../config.js'
import M from 'materialize-css/dist/js/materialize.min.js'
import $ from 'jquery';

class Height extends Component {
  constructor(){
    super();
    this.state = {oldHeight:"SYNCING",open:false};
  }
  blockURLNRG(){
    let url = this.props.settings.hostname + ':' + this.props.settings.port + '/#/blocks/latest'
    if(url.includes('ngrok')) url = this.props.settings.hostname + '/#/blocks/latest'
    return url
  }
  componentDidUpdate(){
    if(this.props.bcHeight !== this.state.oldHeight){
      let element = document.getElementById("bcheight");
      if(element){
        element.classList.remove("fadein");
        void element.offsetWidth;
        element.classList.add("fadein");
      }
      this.setState({oldHeight:this.props.bcHeight});
    }
  }
  onMouseEnter(){
    this.setState({open:true})
  }
  onMouseLeave(){
    this.setState({open:false})
  }
  renderChildBlocks(){
    return this.props.childBlocks.map(({blockchain,height,hash})=>{
      return (
        <a key={hash} style={{border:'1px solid grey',borderTop:'none',borderRight:'none',padding:0}} className='col s12' href={`${blockURL[blockchain.toUpperCase()]}${height}`} rel="noopener noreferrer" target="_blank">
          <div className='childImg' style={{height:'30px'}}>
            <img src={`./${blockchain}.png`}  style={{height:'30px',marginTop:'5px'}} />
          </div>
          <div className='childHeight'>
            {height}
          </div>
        </a>
      )
    })
  }
  openModal(){
    let _this = this;
    var instance = M.Modal.getInstance(document.querySelectorAll('#modalSettings')[0]);
    instance.open();
    setTimeout(()=>{
      _this.props.networksettings.forEach(({id})=>{
        $(`#${id}`)[0].value = _this.props.settings[id];
      });
    },100);
  }
  render() {
    let color = this.props.disconnected ? 'grey' : 'white';
    let display = this.state.open ? '' : 'none';
    return (
      <li onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} style={{width:'100px'}}>

        {this.props.disconnected ?
          <a style={{color,height:'43px',padding:0}} className='rightLink' >
            <div className='childImg' style={{height:'30px'}}>
              <img src={`./bc.png`}  style={{height:'30px',marginTop:'5px'}} />
            </div>
              <div className='childHeight' onClick={this.openModal.bind(this)}  style={{paddingBottom:0, lineHeight:'12px', paddingTop:'3px', fontSize: '0.8em'}}>
                Connect Miner To Send NRG
              </div>
          </a>
        :
          <a href={this.blockURLNRG()} style={{color,height:'43px',padding:0}} className='rightLink' rel="noopener noreferrer" target="_blank" >
            <div className='childImg' style={{height:'30px'}}>
              <img src={`./bc.png`}  style={{height:'30px',marginTop:'5px'}} />
            </div>
            <div className='childHeight' style={{paddingBottom:0,lineHeight:'43px',paddingTop:0}}>
              {this.props.bcHeight}
            </div>
          </a>
        }

        <div style={{display,width:'100%',background:'rgba(39, 39, 39)'}}>
          {this.renderChildBlocks()}
        </div>

      </li>
    )
  }
}

function mapStateToProps(state,props) {

  let networksettings = [
    // {title:"Order Lifespan (# of BC BLocks):",id:'deposit',width:'100%',max:1000,min:10},
    // {title:"Settlement Length (# of BC BLocks):",id:'settlement',width:'100%',max:1000,min:10},
    {title:"BC Miner Hostname: ",id:'hostname',width:"100%"},
    {title:"BC Miner Port: ",id:'port',width:"100%"},
  ];

  let childBlocks = [];
  let latestBlock = state.socket.latestBlock;
  if(latestBlock && latestBlock.blockchainHeaders){
    Object.keys(latestBlock.blockchainHeaders).map(key=>{
      latestBlock.blockchainHeaders[key].forEach((block)=>{
        childBlocks.push(block)
      })
    })
  }

  return {
    settings: state.settings.settings,
    networksettings,
    childBlocks,
    block: state.socket.latestBlock,
    bcHeight: state.socket.bcHeight,
    disconnected: !state.socket.connected
  };
}

//
export default connect(mapStateToProps, {})(Height);
