import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css/dist/js/materialize.min.js'
import PropTypes from 'prop-types';
import {removeWallet} from '../../actions/passport/set';
import './modal.css';

class Modal extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  render() {
    let {
      id,
      classes,
      title,
      decryption,
      isOrder,
      buttonText,
      noDescriptionText,
      component,
      logout,
      chain,
      buttonText2,
      buttonAction,
      buttonAction2,
      style = {},
      noClose,
      noIcon,
      confirmPass,
      closeAction,
      note,
      passwordId,
    } = this.props;
    let buttonClass = 'waves-effect black-text btn-flat left';
    if(!noClose) buttonClass +=' modal-close';
    // console.log({noIcon})
    // console.log((!noIcon && noClose))
    return (
      <div id={id} className={classes} style={style}>
        {(!noIcon && noClose && !closeAction) && <a id='closeModal' className='modal-close' ><i className="material-icons" style={{"color": "black",fontSize: "1em",lineHeight:'24px'}}>close</i></a>}
        {(!noIcon && noClose && closeAction) && <a id='closeModal' onClick={this.close.bind(this)} className='modal-close' ><i className="material-icons" style={{"color": "black",fontSize: "1em",lineHeight:'24px'}}>close</i></a>}


        <div className="modal-content">
          {title && <h4 className="black-text" style={{textAlign:'left'}}>{title}</h4>}

          {component }

          {decryption &&
            <>
            <p className="black-text left" style={{ marginBottom: 0,marginTop:'10px' }}>
              Enter BSEC Password:
            </p>
            <form onSubmit={this.onSubmit.bind(this)} href='#' autoComplete="off" style={{marginTop:'15px'}}>
              <input id={passwordId ? passwordId : "password"} className='blacktext' type="password" required={true} />
            </form>
            </>
          }

          {decryption && confirmPass &&
            <>
            <p className="black-text left" style={{ marginBottom: 0 }}>
              Confirm BSEC Password:
            </p>
            <form onSubmit={this.onSubmit.bind(this)} href='#' autocomplete="off" >
              <input id={passwordId ? `${passwordId}Confirm` : "passwordConfirm"} className='blacktext' type="password" required={true} />
            </form>
            </>
          }

          {isOrder && this.isOrder()}

        </div>
        {(buttonText || buttonText2 || logout) &&
          <div className="modal-footer" style={{marginLeft:'5px',height:'inherit'}}>
            <div className='col s12' style={{height:'50px'}}>
            {this.props.loading ? this.loader() : buttonText &&
              <div style={{border:'1px solid black',fontSize:'12px'}} href="#!" onClick={this.onSubmit.bind(this)} className={buttonClass}>
                <b>{buttonText}</b>
            </div>
            }

            {buttonText2 && buttonAction2 &&
              <div style={{fontSize:'12px',border:'1px solid black',marginRight:'10px'}} href="#!" onClick={this.onSubmit2.bind(this)} className={"waves-effect black-text btn-flat right"}>
                <b>{buttonText2}</b>
              </div>
            }

            {logout && <div href="#!" style={{border:'1px solid black',marginRight:'10px'}} onClick={this.removeWallet.bind(this)} className={"waves-effect black-text btn-flat right modal-close"}><b>END SESSION</b> </div> }
            </div>
            {note &&
              <div className='col s12'>
                <p style={{fontSize:'10px',height:'40px',textAlign:'left'}}>{note}</p>
              </div>
            }
          </div>
        }
      </div>
    )
  }
  isOrder(){
    return (
      <div className='col s12'>
        <p className="grey-text" style={{display:'inline',fontSize:'12px'}}>*Your transaction may not go through depending on other transactions being sent on the network.</p>
      </div>
    )
  }
  loader(){
    return (
      <div className="preloader-wrapper small active" style={{float:'left',marginLeft:'15px'}}>
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div><div className="gap-patch">
            <div className="circle"></div>
          </div><div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    )
  }
  close(event){
    this.props.closeAction()
    if(event && event.preventDefault) event.preventDefault();
  }
  onSubmit2(event){
    this.props.buttonAction2()
    if(event && event.preventDefault) event.preventDefault();
  }
  onSubmit(event){
    this.props.buttonAction()
    if(event && event.preventDefault) event.preventDefault();
  }
  removeWallet(){
    this.props.removeWallet();
  }
  componentDidMount(){
    let elems = document.querySelectorAll(`#${this.props.id}`);
    if(elems) M.Modal.init(elems, {dismissible:false,inDuration:0,outDuration:0});
  }
}

// {decryption && !noDescriptionText && <p className="black-text" style={{marginBottom:0}}>Enter {chain} Password:</p>}


function mapStateToProps(state,props) {
  return {
    loading: state.ui_passport.loading,
  };
}


export default connect(mapStateToProps, {removeWallet})(Modal);
