import {
  SET_PASSPORT_FEE,
  SET_PASSPORT_AMOUNT,
  SET_PASSPORT_ASSET_VIEW,
  SET_LOADING
} from '../actions/types';
import $ from 'jquery';

let wallet = JSON.parse(window.localStorage.getItem('bc-wallet'))

let initialState =  {
  loading:false,
  asset_view: 'nrg',
  address_view: wallet && wallet.emb && Object.keys(wallet.emb) ? Object.keys(wallet.emb)[0] : null,
  amount: null,
  fee : 0,
};

export default function(state: Object = initialState, action) {
  switch(action.type) {
    case SET_LOADING:
      return {...state,loading:action.payload}
    case SET_PASSPORT_ASSET_VIEW:
      return {...state, asset_view: action.payload.view.toLowerCase(), address_view: action.payload.address};
    case SET_PASSPORT_AMOUNT :
      if($('#amount')[0]) $('#amount')[0].value = action.payload;
      return {...state, amount: action.payload};
    case SET_PASSPORT_FEE :
      return {...state, fee: action.payload};
    default:
      return state;
  }
}
