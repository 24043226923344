import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

//Sub Items
import Refresh from './refresh.jsx'
import PendingTransactions from './pendingTransactions.jsx'
import Height from './height.jsx'
import Settings from './settings.jsx';
import Portfolio from './portfolio.jsx';
import Downloads from './download.jsx';

//CSS Import
import './header.css'
import {setAssetView} from '../../actions/ui/passport';

import {assets} from '../../config';
import isElectron from 'is-electron';

class Header extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  componentDidMount(){
    let pair = this.props.location.search ? this.props.location.search.substring(1) : null;
    let type = this.props.location.hash ? this.props.location.hash.substring(1) : null;

    if(pair && pair.length > 1 && !pair.includes('/')){
      // this.props.setAssetView(pair);
    }
  }
  componentWillReceiveProps(newProps){
    if(this.props.location.search !==newProps.location.search){
      let pair = newProps.location.search ? newProps.location.search.substring(1) : null;
      let type = newProps.location.hash ? newProps.location.hash.substring(1) : null;
      if(type){
        let [asset,denomination] = pair.split('/');
      }
      else if(pair){
        // this.props.setAssetView(pair);
      }
    }
  }


  left(){
    let marketplaceDisplay = this.props.location.pathname === '/marketplace' ? 'white' : 'grey';
    return (
      <ul className="left">
        <li><Link to="#" className="borderless">BORDERLESS</Link></li>
        <Portfolio/>
      </ul>
    )
  }
  right(){
    return (
      <ul className='right'>
        <PendingTransactions/>
        {isElectron() ? null : <Downloads/>}
        {isElectron() ? null : <li><a className='rightLink' rel="noopener noreferrer" target="_blank" href="https://forms.gle/BGcwQbbHFnPoD1Qk9" style={{color:'white'}}>feedback</a></li>}
        {this.props.loggedIn && <Settings/>}
        <Refresh/>
        {this.props.loggedIn && <Height/>}
      </ul>
    )
  }
  render() {
    return (
      <div className='minWidth fixedTop' >
        <nav className="navbar-fixed" >
          {this.left()}
          {this.right()}
          <ul className="center" style={{position:'absolute',left:'calc(50% - 100px)',width:'200px'}}>
          </ul>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state,props) {
  return {
    loggedIn: state.passport.wallet.emb && state.passport.wallet.nrg && state.passport.wallet['l-emb']
    // loggedIn: state.passport.wallet.emb
  };
}

//
export default connect(mapStateToProps, {setAssetView})(withRouter(props => <Header {...props}/>));
