import {saveWallet} from './helper';
import {encrypt} from '../../helpers/crypto';
import {SET_PASSPORT_ASSET_VIEW} from '../../types';

export function setBSECWallet(wallet,password,done){
  return async function(dispatch,getState){
    if(wallet.ethWallet) wallet.eth = wallet.ethWallet;
    if(wallet.eth.privateKey.substring(0,2) === "0x") wallet.eth.privateKey = wallet.eth.privateKey.substring(2);
    let enc = encrypt(wallet.eth.privateKey,password);
    dispatch({
      type: SET_PASSPORT_ASSET_VIEW,
      payload:{view:'nrg',address:wallet.eth.address}
    });
    saveWallet(getState(),'nrg',wallet.eth.address,enc,dispatch)
    saveWallet(getState(),'emb',wallet.eth.address,enc,dispatch)
    saveWallet(getState(),'l-emb',wallet.eth.address,enc,dispatch)
  }
}
