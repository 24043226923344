import Neon from "@cityofzion/neon-js";
import {wallet as NeonWallet} from "@cityofzion/neon-js";

export function genNEOWallet(done){
  return function(dispatch,getState) {
    try {
      const privateKey = Neon.create.privateKey();
      let account = new NeonWallet.Account(privateKey);
      let address = account.address;
      let wallet = {privateKey,address,type:'NEO'}
      done(wallet)
    }
    catch(err){
      console.log({err})
      done(err);
    }
  }
}
