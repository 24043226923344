import {genWallet} from '../../helpers/simpleWallet';

export function genNRGWallet(done){
  return async function(dispatch,getState) {
    try {
      let wallet = (await genWallet(null)).ethWallet
      wallet.type = "NRG";
      done(wallet)
    }
    catch(err){
      done(err);
    }
  }
}
