import {getWallet} from '../../helpers/wallet';
import {addPending} from './addPending';
import {web3} from '../../../config'
import {signTransaction, submitTransaction} from '../../helpers/web3';
import {SET_LOADING} from '../../types';

export function payETH({password,address,amount,to},done){
	return function(dispatch,getState) {
		try {
			dispatch({type:SET_LOADING,payload:true})

			let {privateKey} = getWallet(getState,address,'eth',password)
			let from = address;
      let value = web3.utils.toHex(web3.utils.toWei(amount.toString(),'ether'))
  		submitTransaction({from,to,value,data:'0x0',privateKey},dispatch,(err,hash)=>{
        if(!err) addPending(dispatch,getState(),{hash:'0x'+hash,chain:'ETH',to,from,amount})
				dispatch({type:SET_LOADING,payload:false})
        done(err,hash);
      });
		}
		catch(err){
			dispatch({type:SET_LOADING,payload:false})
			done(err);
		}
	}
}
