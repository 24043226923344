import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {take} from 'ramda';
import isElectron from 'is-electron';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import './history.css';
import {txURL} from '../../../config';

import TableHeader from '../../helper/tableHeader';

let fontSize = '12px';
let overflow = "hidden";
let textOverflow = 'ellipsis';

class Past extends Component {
  constructor(){
    super()
    this.state = {emb:false,nrg:false,btc:false,eth:false,neo:false,wav:false,lsk:false}
  }
  renderHistory(){
    let orderHistory = take(50,this.props.pastOrders[this.props.view.toLowerCase()][this.props.address].filter(({from,to}) => true).sort((a,b)=>b.timestamp-a.timestamp)).map(({from,to,value,type,timestamp,color,txOutputIndex,txHash}) => {
      if(type === 'NRG' && timestamp === this.props.timestamp && from === "Mined") color = 'yellow';

      let txExplorerUrl
      let txElem
      if (type === 'NRG') {
        let url = this.props.minerHostname
        txExplorerUrl = `${url}${this.props.minerPort ? ':' + this.props.minerPort : ''}/#/tx/${txHash}`
      } else {
        txExplorerUrl = txURL[type]+txHash
      }

      if (!isElectron()) {
        txElem = <a href={txExplorerUrl} style={{color:'white',overflow,textOverflow,textDecoration:'underline'}} rel="noopener noreferrer" target="_blank">{txHash}</a>
      } else {
        txElem = <span>{txHash}</span>
      }


      return (
        <CSSTransition
              key={txHash+txOutputIndex+from}
              timeout={500}
              classNames="item"
            >
        <tr>
          <td className='white-text' style={{overflow,textOverflow,fontSize}}>{from}</td>
          <td className='white-text' style={{overflow,textOverflow,fontSize}}>{to}</td>
          <td style={{color,fontSize}}>{value} {type}</td>
          <td className='white-text' style={{fontSize}} >{moment(timestamp*1000).format('MM/DD/YYYY, hh:mm:ss')}</td>
          <td className='white-text' style={{overflow,textOverflow,fontSize}}>
              {txElem}
          </td>
        </tr>
        </CSSTransition>
      );
    });

    let headerItems= ['From','To','Amount','Date','Transaction Hash']

    return (
      <div>
        <TableHeader items={headerItems} fontSize='0.9em' lineHeight='40px' />
        <div className='history'>
          <table className="white-text striped" style={{tableLayout:'fixed',fontSize:"1.5em",lineHeight:'30px'}}>
            <tbody style={{lineHeight:'60px',padding:'0px'}}>
              <TransitionGroup component={null}>
                {orderHistory}
              </TransitionGroup>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  renderBuy(){
    let src = `./${this.props.view.toLowerCase()}.png`;
    let link = `/marketplace?${this.props.view.toUpperCase()}/USDT#bid/ask`
    if(this.props.view.toLowerCase() === 'usdt') link = `/marketplace?DAI/USDT#bid/ask`
    if(this.props.view.toLowerCase() === 'emb') link = `/marketplace?USDT/EMB#bid/ask`
    if(this.props.view.toLowerCase() === 'nrg') link = `/marketplace?USDT/NRG#auction`

    link = 'http://borderless.blockcollider.org/';

    if (isElectron()) {
      return (<div />)
    }

    return (
      <div className='col s12' style={{}}>
        <div className='col s12 center' style={{}}>
          <img src={src} className='historyLogo'/>
          <h6 className='white-text' style={{lineHeight:"180%",fontSize:'1rem'}}> No transaction history for {this.props.view.toUpperCase()}.<br/> The Borderless Marketplace is the easiest place to acquire {this.props.view.toUpperCase()}.</h6>
          <br/>
          <a href={link} target="_blank" className="btn black-text" style={{backgroundColor:'white'}} action="submit"> Buy <b>{this.props.view}</b></a>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div>
        {
          this.props.pastOrders[this.props.view.toLowerCase()] &&
          this.props.pastOrders[this.props.view.toLowerCase()][this.props.address] &&
          this.props.pastOrders[this.props.view.toLowerCase()][this.props.address].length > 0
          ? this.renderHistory()
          : this.renderBuy()}
      </div>
		)
  }
}


function mapStateToProps(state,props) {
  return {
    timestamp: state.socket.timestamp,
    pastOrders: state.transfer_history,
    view: state.ui_passport.asset_view,
    address: state.ui_passport.address_view,
    minerHostname: state.settings.settings.hostname,
    minerPort: state.settings.settings.port,
    minerSecureCookie: state.settings.settings.scookie,
  };
}

export default connect(mapStateToProps, {})(Past);
