import {
  SET_PENDING_TRANSACTIONS,
  OVERRIDE_PENDING_TRANSACTIONS
} from '../actions/types';

let initialState =  {
  transactions: JSON.parse(window.localStorage.getItem("pendingTransactions")) ? JSON.parse(window.localStorage.getItem("pendingTransactions")) : {},
};

export default function(state: Object = initialState, action) {
  switch(action.type) {
    case OVERRIDE_PENDING_TRANSACTIONS:
      let transactions = action.payload;
      window.localStorage.setItem("pendingTransactions",JSON.stringify(transactions));
      return {...state, transactions: transactions};

    case SET_PENDING_TRANSACTIONS:
      let tx = action.payload;
      transactions = state.transactions
      transactions[tx.hash] = tx;
      window.localStorage.setItem("pendingTransactions",JSON.stringify(transactions));
      return {...state, transactions: transactions};

    default:
      return state;
  }
}
