import {saveWallet} from './helper';
import {encrypt} from '../../helpers/crypto'
var cryptography = require('@liskhq/lisk-cryptography');

export function setLSKWallet(walletKey,password,done){
  return function(dispatch,getState) {
    try {
      let address = cryptography.getAddressFromPassphrase(walletKey);
      let enc = encrypt(walletKey,password);
      saveWallet(getState(),'lsk',address,enc,dispatch)
      done(null)
    }
    catch(err){
      done(err);
    }
  }
}

export function getLSKAddress(walletKey){
  return function(dispatch,getState) {
    try {
      let address = cryptography.getAddressFromPassphrase(walletKey);
      return address;
    }
    catch(err){
      return;
    }
  }
}
