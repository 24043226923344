import {
  SET_SETTINGS,
  BLOCKS_MINED_ADD
} from '../types';
import {init} from '../../reducers/socket';

export function updateSettings(settings,done) {
  return function(dispatch,getState) {

    //if we changed the socket params, change the socket
    if(getState().socket.location.hostname !== settings.hostname || getState().socket.location.port !== settings.port){
      dispatch({type:BLOCKS_MINED_ADD,payload:{height:'SYNCING',timestamp:0}});
      if(getState().socket.socket.connected) getState().socket.socket.disconnect();
      init({hostname:settings.hostname,port:settings.port},dispatch,{getState});
    }
    
    dispatch({
      type: SET_SETTINGS,
      payload:settings
    });

    setTimeout(()=>{done()},200);
  }
}
