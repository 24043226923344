import React, { Component } from 'react';
import { connect } from 'react-redux';
import {multiverseUrl,blockURL,assets} from '../../config.js'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js'

class Portfolio extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(){
    super();
    this.state = {open:false};
  }
  onMouseEnter(){
    this.setState({open:true})
  }
  onMouseLeave(){
    this.setState({open:false})
  }
  renderChildBlocks(){
    return this.props.balances.map(({blockchain,balance,address},i)=>{
      let borderBottom = i !==this.props.balances.length -1 ? '1px solid grey' : '';
      return (
        <a className='col s12' id={`${balance}${address}${i}`} key={`${balance}${address}${i}`} style={{borderBottom}} key={blockchain} onClick={this.copyFrom.bind(this,address)}>
          <div className='childImg' style={{height:'50px'}}>
            <img src={`./${blockchain}.png`}  style={{height:'40px',marginTop:'10px'}} />
          </div>
          <div className='childBalance'>
            <span style={{marginLeft:'10px',textOverflow:'ellipsis',overflow:'hidden'}}>{address}</span>
            <br/>
            <span style={{marginLeft:'10px',color:'white'}}><b>{balance} {blockchain.toUpperCase()}</b></span>
          </div>
        </a>
      )
    })
  }
  copyFrom(address){
    var textField = document.createElement('textarea')
    textField.innerText =  address
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    M.toast({html: 'Copied Address',classes:"black",displayLength:3000});
  }
  render() {
    let color = this.props.disconnected ? '#f44336' : 'white';
    let display = this.state.open ? '' : 'none';
    let passportDisplay = this.props.location.pathname === '/' ? 'white' : 'grey';

    return (
      <li onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} >
        <Link className='topLink' to="/" style={{color:'grey',color:passportDisplay}} onClick={this.onMouseLeave.bind(this)}>
          portfolio
        </Link>
        {this.props.balances.length > 0 &&
          <div className='childBalances' style={{display,background:'rgba(39, 39, 39)'}}>
            {this.renderChildBlocks()}
          </div>
        }
      </li>
    )
  }
}

function mapStateToProps(state,props) {
  let balances = [];
  let wallet = state.passport.wallet;
  let order = ['nrg','emb','btc','eth','neo','wav','lsk']
  let {assets} = require('../../config.js')
  // if(wallet){
  //   assets.map(key => {
  //     key = key.toLowerCase()
  //     if(wallet[key]) balances.push({address:wallet[key].address,blockchain:key.toLowerCase(),balance:state.passport[key.toLowerCase()]})
  //   });
  // }
  return {
    balances
  };
}

//
export default connect(mapStateToProps, {})(withRouter(props => <Portfolio {...props}/>));
