import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setAssetView} from '../../../actions/ui/passport'
import {getBalancesDispatch} from '../../../actions/passport/get';
import Modal from '../../helper/modal';
import ShowWallet from '../../helper/showWallet';
import {withRouter} from 'react-router-dom';
import {
  setBTCWallet,setETHWallet,setNEOWallet,setWAVWallet,setLSKWallet,setDAIWallet,setUSDTWallet,setNRGWallet, setLEMBWallet, setEMBWallet,
  getBTCAddress,getETHAddress,getNEOAddress,getWAVAddress,getLSKAddress,getDAIAddress,getUSDTAddress, getEMBAddress, getNRGAddress, getLEMBAddress
  ,removeAsset,removeWallet
} from '../../../actions/passport/set';

import {getNEOTransfers,getBTCTransfers,getETHTransfers,getLSKTransfers,getWAVTransfers,getNRGTransfers,getEMBTransfers,getDAITransfers,getUSDTTransfers} from '../../../actions/transfers/get';
import {genNEOWallet,genBTCWallet,genETHWallet,genLSKWallet,genWAVWallet,genDAIWallet,genUSDTWallet, genNRGWallet, genEMBWallet, genLEMBWallet} from '../../../actions/passport/generate';

import M from 'materialize-css/dist/js/materialize.min.js'
import $ from "jquery";
import Circle from '../../helper/circle.jsx';
var FileSaver = require('file-saver');

const shortenHash = function (hash: string, partLength: number = 5) {
  return hash.slice(0, partLength) + '...' + hash.slice(hash.length - partLength, hash.length)
}

class Asset extends Component {
  constructor(){
    super()
    this.state = {textType:'Private Key',name:'BTC',wallet:{address:'',type:'',privateKey:''}}
  }
  openModal(){
    let currencies = {
      "btc":'Private Key',
      "eth":'Private Key',
      "neo":'Private Key',
      "wav":'Seed',
      'nrg':'Private Key',
      'l-emb': 'Private Key',
      'emb': 'Private Key',
      "lsk":'Passphrase'
    };
    setTimeout(()=>{
      this.setState({name:this.props.title,textType:currencies[this.props.title]})
      var instance = M.Modal.getInstance(document.querySelectorAll(`#modal${this.props.div}`)[0]);
      instance.open();
      setTimeout(()=>{
        if($(`#wallet${this.props.div}`)) $(`#wallet${this.props.div}`).focus()
      },100)
    },200)
  }
  openWallet(){
    let _this = this;
    setTimeout(()=>{
      this.props[`gen${this.props.title.replace('-','').toUpperCase()}Wallet`]((wallet)=>{
        _this.setState({wallet})
        var instance = M.Modal.getInstance(document.querySelectorAll(`#modal${this.props.div}Wallet`)[0]);
        instance.open();
        setTimeout(()=>{
          if($('#'+_this.props.title.toUpperCase() + "passwordWallet")) $('#'+_this.props.title.toUpperCase() + "passwordWallet").focus()
        },100)
      })
    },200)
  }
  onSubmit(event){
    event.preventDefault();
    this.submitOperation()
  }
  submitOperation(){
    let pass = $(`#pass${this.props.div}`).val();
    let pass2 = $(`#pass${this.props.div}Confirm`).val();
    if(pass !==pass2){
      M.toast({html: 'Passwords don\'t match',classes:"red"});
      return;
    }
    if(pass2 === ''){
      M.toast({html: 'Password must be set',classes:"red"});
      return;
    }
    // console.log({pass2})

    let walletKey = $(`#wallet${this.props.div}`).val().trim();
    this.setWallet(walletKey,this.state.address,pass);
  }
  setWallet(privateKey,address,password){
    this.props[`set${this.props.title.replace('-','').toUpperCase()}Wallet`](privateKey,password,(err)=>{
      if(err) {
        M.toast({html: `Wallet could not be imported`,classes:"red",displayLength:3000});
      }
      else {
        this.setState({address:null})
        var instance = M.Modal.getInstance(document.querySelectorAll(`#modal${this.props.div}`)[0]);
        if(instance) instance.close();
        setTimeout(()=>{
          this.props.getBalancesDispatch()
          this.props[`get${this.state.name.replace('-','').toUpperCase()}Transfers`](()=>{})
        },100)
        setTimeout(()=>{
          this.props.setAssetView(this.props.title,address)
        },200)
      }
    })
  }
  handleClick(address){
    // console.log({address})
    this.props.setAssetView(this.props.title,address)
    // this.props.history.push({search:this.props.title.toUpperCase()});
    $('#to').focus();
  }
  removeAsset(asset,address,e){
    e.stopPropagation();
    e.preventDefault()
    this.props.setAssetView('nrg',this.props.firstAddress)
    this.props.removeAsset(asset,address)
  }
  removeWallet(e){
    e.stopPropagation();
    e.preventDefault()
    this.props.removeWallet()
  }
  renderWallet(){
    let component = (<ShowWallet type={this.props.title.toUpperCase()} wallet={this.state.wallet} onSubmit={this.saveWallet.bind(this)} />)
    return (
      <Modal noClose={true} style={{width:'500px'}} id={`modal${this.props.div}Wallet`} classes='modal modalOrders black-text' component={component} buttonText={"Download and Save*"} note={'*It is strongly encouraged to save this wallete in a safe and secure manner'} buttonAction={this.saveWallet.bind(this)} />
    )
  }
  saveWallet(event){
    if(event && event.preventDefault) event.preventDefault();
    let pass = $('#'+this.props.title.toUpperCase() + "passwordWallet").val()
    let pass2 = $('#'+this.props.title.toUpperCase() + "passwordWalletConfirm").val()
    if(pass !==pass2){
      M.toast({html: 'Passwords don\'t match',classes:"red"});
      return;
    }
    if(pass2 === ''){
      M.toast({html: 'Password must be set',classes:"red"});
      return;
    }
    var blob = new Blob([JSON.stringify(this.state.wallet)], {type: "text/javascript;charset=utf-8"});
    FileSaver.saveAs(blob, this.state.wallet.type+".json");
    this.setWallet(this.state.wallet.privateKey,this.state.wallet.address,pass);

    var instance = M.Modal.getInstance(document.querySelectorAll(`#modal${this.props.div}Wallet`)[0]);
    if(instance) instance.close();
  }
  onChangePriv(){
    let walletKey = $(`#wallet${this.props.div}`).val().trim();
    let address = this.props[`get${this.props.title.replace('-','').toUpperCase()}Address`](walletKey);
    this.setState({address})
  }
  renderModal(){
    let component = (
      <div>
        <img src={`./${this.state.name.toLowerCase()}.png`} style={{height:'50px',float:'left'}} />
        <h4 className='black-text' style={{marginTop: '7px',marginLeft:'10px',display:'inline-block'}}> {`Import ${this.state.name.toUpperCase()} Wallet`}</h4>
        <div className='col s12' style={{marginTop:'5px'}}>
          <p className="black-text">Enter {this.state.textType}:</p>
          <form onSubmit={this.onSubmit.bind(this)} href='#' autoComplete='off'>
            <input id={`wallet${this.props.div}`} onChange={this.onChangePriv.bind(this)} className='black-text' type="password" label={`${this.state.textType}:`} style={{color:"black !important"}} required={true} />
          </form>
          <p className="black-text">Enter {this.state.name.toUpperCase()} Password:</p>
          <form onSubmit={this.onSubmit.bind(this)} href='#' autoComplete='off'>
            <input id={`pass${this.props.div}`} className='black-text' type="password" label={"Password:"} style={{color:"black !important"}} required={true} />
          </form>
          <p className="black-text">Confirm {this.state.name.toUpperCase()} Password:</p>
          <form onSubmit={this.onSubmit.bind(this)} href='#' autoComplete='off'>
            <input id={`pass${this.props.div}Confirm`} className='black-text' type="password" label={"Password:"} style={{color:"black !important"}} required={true} />
          </form>
          <p className="black-text" style={{marginTop:'5px'}}>Address:</p>
          <p className="grey-text" style={{marginBottom:'10px',height:'20px'}}>{this.state.address ? this.state.address : `Please Enter In A Valid ${this.state.textType}`}</p>
        </div>
      </div>
    );
    return (
      <Modal style={{width:'500px'}} id={`modal${this.props.div}`} classes='modal black-text' component={component} buttonText={"Confirm"} noClose={true} buttonAction={this.submitOperation.bind(this)} />
    )
  }
  renderUpload(){
    return (
      <div className='col s12 center' style={{width:`calc(100% - 1px`,marginTop:'10px'}}>
        <div className='col s6' style={{marginBottom:'20px',marginTop:'10px',padding:0}}>
          <a className=" center hoverA" onClick={this.openModal.bind(this)} >Read In Wallet</a>
        </div>
        <div className='col s6' style={{marginBottom:'20px',marginTop:'10px',padding:0}}>
          <a className="center hoverA" onClick={this.openWallet.bind(this)} >Generate Wallet</a>
          </div>
      </div>
    )
  }
  renderTitle(){
    return (
      <h6 style={{paddingLeft:'45px',fontSize:'20px',marginBottom:0,marginTop:'13px'}} className='white-text'>
        {this.props.title.toUpperCase()}
      </h6>
    )
  }
  renderDelete(address){
    return (
      <a className="trash" onClick={this.removeAsset.bind(this,this.props.title,address)}>
        <span className='material-icons trashIcon'>delete</span>
      </a>
    )
  }
  renderImage(){
    return (<img src={`./${this.props.title.toLowerCase()}.png`} className='assetLogo'/>)
  }
  renderBalance(){
    // background: idx % 2 === 0 ? 'rgb(37, 37, 37, 0)' : 'rgb(37, 37, 37, 0)'
    let items = this.props.addresses.map((address,idx)=>{
      let selected = this.props.addressView === address.toLowerCase() && this.props.assetView === this.props.title;
      let color = selected ? 'black' : 'white';
      let background = selected ? 'rgb(255,255,255,0.9)' : '';
      let func = this.handleClick.bind(this,address);
      let isBC = ['emb','l-emb','nrg'].includes(this.props.title.toLowerCase());
      let usd = Math.round(100*this.props.availableAmount[address]*this.props.price[this.props.title.toLowerCase()])/100
      if(isNaN(usd)) usd = 0;
      return (
        <div className='col s12 addressHover' key={`${address}${idx}`} onClick={func} style={{background}}>
          <h6 className='addr'>
            <span style={{color,opacity:0.7,fontSize:'0.75rem'}}>{address}</span>
            {selected && !(idx === 0 && isBC) && this.renderDelete(address)}
            <br/>
            <span style={{color,fontSize:'0.7rem'}}>
              {this.props.availableAmount[address] ? this.props.availableAmount[address] : 0} {this.props.title.toUpperCase()}
              {!isBC && <span style={{color,opacity:0.75}}> ({usd} USD)</span>}
            </span>
          </h6>
        </div>
      )
    })
    return (
      <div>
        {items}
      </div>
    )
  }
  // <Circle big={false} {...this.props}/>
  render() {
    // let border = this.props.assetView === this.props.title ? '2px solid white' : '2px solid grey';
    let borderTop = this.props.index !==0 ? '1px solid grey' : '';
    let borderBottom = this.props.index !==9 ? '1px solid grey' : '';


    let src = `./${this.props.title.toLowerCase()}.png`;
    let cursor = this.props.addresses ? 'pointer' : '';
    let marginTop = this.props.index !==0 ? '0px' : '0px';
    return (
      <div>
        <div className='col s12 ' style={{padding: 0,cursor,zIndex:5,borderTop,borderBottom,marginTop}}>
          <div className='col s12' style={{height:'45px'}}>
            {this.renderImage()}
            {this.renderTitle()}
          </div>

          <div className='col s12' style={{}}>
            {this.props.addresses.length > 0 && this.renderBalance()}
            {this.renderUpload()}
          </div>

        </div>
        {this.renderWallet()}
        {this.renderModal()}
      </div>
    );
  }
}

function mapStateToProps(state,props) {
  return {
    firstAddress: Object.keys(state.passport.wallet.nrg)[0],
    price: state.price,
    availableAmount: state.passport[props.title] ? state.passport[props.title] : 0,
    assetView: state.ui_passport.asset_view.toLowerCase(),
    addressView: state.ui_passport.address_view.toLowerCase(),
    addresses: state.passport.wallet[props.asset] ? Object.keys(state.passport.wallet[props.asset]) : []
  };
}

export default connect(mapStateToProps, {
  getBTCAddress,getETHAddress,getNEOAddress,getWAVAddress,getLSKAddress,getDAIAddress,getUSDTAddress,getEMBAddress, getNRGAddress, getLEMBAddress,
  genNEOWallet,genBTCWallet,genETHWallet,genLSKWallet,genWAVWallet,genDAIWallet,genUSDTWallet,genNRGWallet, genLEMBWallet, genEMBWallet,
  setAssetView,setBTCWallet,setETHWallet,setNEOWallet,setWAVWallet,setLSKWallet,getBalancesDispatch,removeAsset,removeWallet,setDAIWallet,setUSDTWallet, setEMBWallet, setNRGWallet, setLEMBWallet,
  getNEOTransfers,getBTCTransfers,getETHTransfers,getLSKTransfers,getWAVTransfers,getNRGTransfers,getEMBTransfers,getDAITransfers,getUSDTTransfers})(withRouter(props => <Asset {...props}/>));
