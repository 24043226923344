import {decrypt} from './crypto';
import {wallet as NeonWallet} from "@cityofzion/neon-js";
var CoinKey = require('coinkey');
var cryptography = require('@liskhq/lisk-cryptography');
const WavesAPI = require('@waves/waves-api');

export function getWallet(getState,address,chain,password) {
  let privateKey = "";
  try {
    let privateKey;
    switch(chain){
      case 'usdt':
        privateKey = decrypt(getState().passport.wallet.usdt[address].privateKey, password, getState().passport.wallet.usdt[address].salt, getState().passport.wallet.usdt[address].iv).substring(2);
        break;
      case 'dai':
        privateKey = decrypt(getState().passport.wallet.dai[address].privateKey, password, getState().passport.wallet.dai[address].salt, getState().passport.wallet.dai[address].iv).substring(2);
        break;
      case 'btc':
        privateKey = decrypt(getState().passport.wallet.btc[address].privateKey, password, getState().passport.wallet.btc[address].salt, getState().passport.wallet.btc[address].iv);
        break;
      case 'eth':
        privateKey = decrypt(getState().passport.wallet.eth[address].privateKey, password, getState().passport.wallet.eth[address].salt, getState().passport.wallet.eth[address].iv).substring(2);
        break;

      case 'neo':
        privateKey = decrypt(getState().passport.wallet.neo[address].privateKey, password, getState().passport.wallet.neo[address].salt, getState().passport.wallet.neo[address].iv);
        let account = new NeonWallet.Account(privateKey);
        privateKey = account;
        break;

      case 'wav':
        let seed = decrypt(getState().passport.wallet.wav[address].privateKey, password, getState().passport.wallet.wav[address].salt, getState().passport.wallet.wav[address].iv);
        const Waves = WavesAPI.create(WavesAPI.MAINNET_CONFIG);
        privateKey = Waves.Seed.fromExistingPhrase(seed);
        break;

      case 'lsk':
        privateKey = decrypt(getState().passport.wallet.lsk[address].privateKey, password, getState().passport.wallet.lsk[address].salt, getState().passport.wallet.lsk[address].iv);
        break;

      case 'emb':
        privateKey = decrypt(getState().passport.wallet.emb[address].privateKey, password, getState().passport.wallet.emb[address].salt, getState().passport.wallet.emb[address].iv).substring(2);
        break;

      case 'nrg':
        privateKey = decrypt(getState().passport.wallet.nrg[address].privateKey, password, getState().passport.wallet.nrg[address].salt, getState().passport.wallet.nrg[address].iv);
        break;
    }
    return {privateKey,address}
  }
  catch(err){
    throw new Error('Incorrect Decryption Password')
  }
}
