import {FETCHED_FEE} from '../../types';
import {getFee} from '../../helpers/bitcoin';

export function getBTCFee(amount,address,done){
	return async function(dispatch,getState) {
    try {
      let fee = await getFee({
        from: address,
        btc: amount,
        network: "mainnet"
      });
      dispatch({
        type:FETCHED_FEE,
        payload: fee
      })
			if(done) done();
    }
    catch(err){
			console.log({err})
      dispatch({
        type:FETCHED_FEE,
        payload: 0
      })
			if(done) done();

    }
  }
}
