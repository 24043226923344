import {FETCHED_BALANCE} from '../../types';
const WavesAPI = require('@waves/waves-api');

export function getWAVBalanceDispatch(){
  return function(dispatch,getState){
    getWAVBalance(dispatch,getState())
  }
}

export async function getWAVBalance(dispatch,state){
  const Waves = WavesAPI.create(WavesAPI.MAINNET_CONFIG);
  for(let address of Object.keys(state.passport.wallet.wav)){
    let balance = await Waves.API.Node.addresses.balance(address)
    dispatch({
      type:FETCHED_BALANCE,
      payload: {balance:balance.balance / Math.pow(10,8), asset:'wav',address}
    });
  }
}
