import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css/dist/js/materialize.min.js'
import Assets from './Assets';
import Transfers from './Transfers';
import History from './History';
import './passport.css';
import Start from './Upload';
import Modal from '../helper/modal';

class Home extends Component {
  componentDidMount () {
    if(!this.props.loggedIn){
      const modalElement = M.Modal.getInstance(document.querySelector('#termsModal'))
      modalElement.open()
    }
  }

  closeTerms () {
    const modalElement = M.Modal.getInstance(document.querySelector('#termsModal'))
    modalElement.close()
  }

  render() {
    let termsAndConditions = (
      <div id='terms'>
        <h4>SECURITY WARNINGS</h4>
        <hr/>

        <p>You are responsible for your own computer security. If your machine is compromised you will lose your NRG, EMB, access to any contracts, digital assets, and possibly more. </p>
        <p>You are responsible for your own actions. If you mess something up, lose access to cryptocurrency or break any laws while using this software, it's your fault, and your fault only.</p>

        <h4 style={{marginTop:'20px'}}>LEGAL WARNING</h4>
        <hr/>
        <h5>DISCLAIMER OF LIABILITIES AND WARRANTIES</h5>

        <ul style={{marginTop:'5px'}}>
          <li>The user expressly knows and agrees that the user is using the Block Collider platform at the user’s sole risk.</li>
          <li>The user acknowledges that the user has an adequate understanding of the risks, usage and intricacies of cryptographic tokens and blockchain-based open source software, Block Collider platform and the use of Block Collider applications relative to other blockchains. </li>
          <li>The user acknowledges and agrees that, to the fullest extent permitted by any applicable law, the disclaimers of liability contained herein apply to any and all damages or injury whatsoever caused by or related to risks of, use of, or inability to use, Block Collider or the Block Collider platform under any cause or action whatsoever of any kind in any jurisdiction, including, without limitation, actions for breach of warranty, breach of contract or tort (including negligence) and that neither Block Collider International Anstalt (i.e. Block Collider Foundation) nor Block Collider team shall be liable for any indirect, incidental, special, exemplary or consequential damages, including for loss of profits, goodwill or data that occurs as a result.</li>
          <li>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. Therefore, some of the above limitations in this section may not apply to a user. In particular, nothing in these terms shall affect the statutory rights of any user or exclude injury arising from any willful misconduct or fraud of Block Collider Foundation. </li>
        </ul>

        <h5>FORCE MAJEURE</h5>
        <p>
          <strong>Block Collider</strong> is finally not liable for:
        </p>
        <ul style={{marginTop:'5px'}}>
          <li>Unavoidable casualty</li>
          <li>Delays in delivery of materials</li>
          <li>Embargoes</li>
          <li>Government orders</li>
          <li>Acts of civil or military authorities</li>
          <li>Lack of energy</li>
          <li>Any similar unforeseen event that renders performance commercially implausible.</li>
        </ul>
      </div>
    )

    if (this.props.loggedIn !== undefined && this.props.loggedIn !== false) {
      return (
        <div className='passportTop'>

        <div id='passport' className='minWidth container row passportDiv'>
            <Assets/>
            <div className='col s9' style={{padding:0}}>
              <Transfers/>
              <History/>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <Modal id='termsModal' noClose={true} noIcon={true} classes='modal modalOrder' title='Terms and Conditions' component={termsAndConditions} buttonAction={this.closeTerms.bind(this)} buttonText='I agree'/>
          <Start/>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.passport.wallet.emb && state.passport.wallet.nrg && state.passport.wallet['l-emb'] && state.passport.wallet['emb'] && !state.passport.wallet.emb.address
  }
}

export default connect(mapStateToProps,{})(Home);
