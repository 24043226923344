import {addPending} from './addPending';
import {signTransaction, submitTransaction} from '../../helpers/web3';
import {web3, USDT} from '../../../config'
import {getWallet} from '../../helpers/wallet';
import {SET_LOADING} from '../../types';

export function payUSDT({password,amount,address,to,fee},done){
  return async function(dispatch,getState){
		try {
      dispatch({type:SET_LOADING,payload:true})
      let {privateKey} = getWallet(getState,address,'usdt',password)
      let from = address
      let value = web3.utils.toHex(0)
      let data = USDT.methods.transfer(to,
        web3.utils.toHex(Math.floor(Math.pow(10,6)*(parseFloat(amount))))
      ).encodeABI()
      submitTransaction({to:USDT._address,from,value,data,privateKey},dispatch,(err,hash)=>{
        if(!err) addPending(dispatch,getState(),{hash:'0x'+hash,chain:'USDT',to,from,amount})
        dispatch({type:SET_LOADING,payload:false})
        done(err,hash);
      });
    }
		catch(err){
      console.log({err})
      dispatch({type:SET_LOADING,payload:false})
			done(err);
		}
  }
}
