import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Modal from './modal';
import M from 'materialize-css/dist/js/materialize.min.js'
import $ from "jquery";
import {colors} from '../../config'

class Circle extends Component {
  constructor(){
    super()
    this.state = {textType:'Private Key',name:'BTC',pieSeries:null}
  }
  componentDidUpdate(prevProps,prevState){
    this.updateChart();
  }
  updateChart(){
    try {
      if(this.chart === null){
        this.initiateChart()
        return
      }

      let available = this.props.spendingAmount > 0 ? Math.max(0,this.props.availableAmount - this.props.spendingAmount) : Math.max(1,this.props.availableAmount);
      if(this.props.spendingAmount === 0 && this.props.collateralizedAmount > 0) available = Math.max(0,this.props.availableAmount)

      let data = [{
          "label": "Available",
          "amount": this.round(available),
          "amountReal": this.round(this.props.availableAmount - this.props.spendingAmount)
      },
      {
        "label": "Spending",
        "amount": this.round(this.props.spendingAmount),
        "amountReal": this.round(this.props.spendingAmount)
      }]

      if(this.props.collateralized){
        data.push({
          "label": "Collateralized",
          "amount": this.round(this.props.collateralizedAmount),
          "amountReal": this.round(this.props.collateralizedAmount)
        })
      }

      if(this.props.leaseLocked){
        data.push({
          "label": "Leases Locked",
          "amount": this.round(this.props.leaseAmount),
          "amountReal": this.round(this.props.leaseAmount)
        })
      }

      if(this.props.mining){
        data.push({
          "label": "Leases Locked",
          "amount": this.round(this.props.miningAmount),
          "amountReal": this.round(this.props.miningAmount)
        })
      }

      if(this.props.noLegend){
        this.updateColors(this.pieSeries)
      }
      this.chart.data = data
    } catch(err){
      console.log({err})
      let _this = this;
      setTimeout(()=>{
        _this.updateChart()
      },1000)
    }

  }
  updateColors(pieSeries){
    let color = colors[this.props.view.toUpperCase()];
    if(['EMB','NRG'].includes(this.props.view.toUpperCase()) ){
      pieSeries.colors.list = [
        am4core.color(color),
        am4core.color(color).lighten(0.3),
        am4core.color(color).lighten(0.7)
      ]
    }
    else {
      pieSeries.colors.list = [
        am4core.color(color),
        am4core.color(color).lighten(0.5)
      ]
    }
  }
  initiateChart(){

    let element = document.getElementById(this.props.div);
    if(element){
      let chart = am4core.create(this.props.div, am4charts.PieChart);

      // Add and configure Series
      chart.innerRadius = am4core.percent(50);
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "amount";
      pieSeries.dataFields.category = "label";
      pieSeries.dataFields.amountReal = 'amountReal';
      pieSeries.dataFields.color = 'color';

      this.updateColors(pieSeries)

      pieSeries.labels.template.disabled = true;
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.tooltipText = "";
      var hs = pieSeries.slices.template.states.getKey("hover");
      hs.properties.scale = 1;

      if(!this.props.noLegend){
        chart.legend = new am4charts.Legend();
        chart.legend.valueLabels.template.text = "";
        chart.legend.position = "below";
        chart.legend.labels.template.text = "[white]{category}: [bold white]{amountReal}[/]";
        chart.legend.itemContainers.template.togglable = false;
      }

      this.pieSeries = pieSeries;

      this.chart = chart;

      this.updateChart()
    }
  }
  componentDidMount(){
    let _this = this;
    // setTimeout(()=>{
      try{
        this.initiateChart();
      }
      catch(err){
          console.log({err})
      }
    // },100)
  }
  click(e){
    e.stopPropagation()
    return
  }
  render() {
    let zoom = this.props.big ? 1.2 : 1;
    let marginTop = !this.props.big && (this.props.view === 'nrg' || this.props.view === 'emb') ? '-15px' : 0;
    let maxHeight = !this.props.big && (this.props.view !=='nrg' && this.props.view !=='emb') ? '100px' : '';
    let svgClass = !this.props.big && (this.props.view !=='nrg' && this.props.view !=='emb') ? 'svg100' : '';
    if(!this.props.big && this.props.view === 'nrg' && this.props.view === 'emb') {
      svgClass = 'svg140';
      maxHeight = '140px';
    }

    return (
      <div className={`${this.props.div} ${svgClass}`} onClick={this.click.bind(this)} id={this.props.div} style={{maxWidth:'350px',maxHeight,marginTop,zoom}}> </div>
    )
  }
  round(num){
    return num
  }
}

function mapStateToProps(state,props) {
  return {
    availableAmount: state.passport[props.view][props.address] ? state.passport[props.view][props.address] : 0,
    collateralizedAmount: props.collateralized ? state.passport[props.collateralized] : null,
    leaseAmount: props.leaseLocked && state.passport[props.leaseLocked] ? state.passport[props.leaseLocked] : 0,
    spendingAmount: state.ui_passport.asset_view === props.view && state.ui_passport.amount ? state.ui_passport.amount : 0
  };
}

export default connect(mapStateToProps, {})(Circle);
