import {getWallet} from '../../helpers/wallet';
import {addPending} from './addPending';
import {SET_LOADING} from '../../types';
import { sendTx, BASE_BLOCK_SIZE } from '../../helpers/bc';
import { getNRGBalance, getSpendableOutpoints } from '../../passport/get';
import {fee} from '../../../config';
import {
  createNRGTransferTransaction
} from 'bc-sdk/dist/transaction'
import BN from 'bn.js'

import {
  COIN_FRACS,
  Currency,
  CurrencyInfo,
  humanToInternalAsBN,
  internalToHuman,
  internalBNToHuman,
  internalToBN,
} from 'bc-sdk/dist/utils/coin'
import {
  createOutPoint,
  convertProtoBufSerializedBytesToBuffer, createTransactionInput
} from 'bc-sdk/dist/utils/protoUtil'

import { normalizeHexString } from 'bc-sdk/dist/utils/string'

import {createUnlockSig} from 'bc-sdk/dist/script/templates';

let inputSize = (outPoint) => {
  let exampleScript = '0x9c029c0b09edf3f62b085d196b3d55f84ae0db2de29753aa252eeda103d9d3cd48ec6bd47644640803d2b0840a16632dfe108819a133a386d2a3b8ae5b54f68e01';
  let bn = new BN(Buffer.from(outPoint.outpoint.value,'base64').toString('hex'),16);
  let outpoint = createOutPoint(outPoint.outpoint.hash, outPoint.outpoint.index, bn)
  let input = createTransactionInput(outpoint, exampleScript)
  return {size:input.serializeBinary().length, value: bn}
}

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function consolidateNRG({password,address},done){
  return async function(dispatch,getState){
    try {
      let state = getState()

      let { privateKey: bcPrivateKeyHex} = getWallet(getState,address, 'nrg', password)
      dispatch({type:SET_LOADING,payload:true})

      await timeout(10)

      let spendableOutpointsList = getState().passport.spendableOutpoints[address]
      if(!spendableOutpointsList ||spendableOutpointsList.length === 0){
        await getSpendableOutpoints(dispatch, state)
        spendableOutpointsList = getState().passport.spendableOutpoints[address]
      }
      console.log({spendableOutpointsList})
      let txs = [];
      let sum = 0;
      let arr = [];
      let maxSize = Math.floor(BASE_BLOCK_SIZE * 2 / 3) - 6000;
      let amount = new BN(0);
      spendableOutpointsList.map((s,i)=>{
        let {size,value} = inputSize(s);
        console.log({size,value,hash:s.outpoint.hash,value:internalToHuman(value,COIN_FRACS.NRG),index:s.outpoint.index,height:s.blockHeight})
        if(sum + size <= maxSize) {
          sum += size;
          amount = amount.add(value)
          arr.push(s);
        }
        else {
          amount = amount.sub(fee)
          let tx = createNRGTransferTransaction(arr, address, bcPrivateKeyHex, address, internalToHuman(amount,COIN_FRACS.NRG),'0',true)
          txs.push(tx)
          arr = [];
          sum = size;
          amount = value
          arr.push(s);
        }
      })
      if(amount.gt(fee)){
        amount = amount.sub(fee)
        let tx = createNRGTransferTransaction(arr, address, bcPrivateKeyHex, address, internalToHuman(amount,COIN_FRACS.NRG),'0',true)
        txs.push(tx)
      }
      dispatch({type:SET_LOADING,payload:false})

      done(null,txs)
    }
    catch(err){
      dispatch({type:SET_LOADING,payload:false})
      done(err);
    };
  }
}

export function sendTxs({address,txs},done){
  return async function(dispatch,getState){
    try {
      let state = getState()
      dispatch({type:SET_LOADING,payload:true})

      await timeout(10)
      let result = await Promise.all(txs.map((tx)=>{return sendTx(state.settings.settings,tx)}))
      console.log({result})
      result.map((data)=>{
        if(data.txHash){
          let tx = null;
          txs.map((t)=>{
            if(t.getHash() === data.txHash) tx = t;
          })
          if(tx){
            let amount = new BN(Buffer.from(tx.getOutputsList()[0].getValue(),'base64').toString('hex'),16);
            amount = amount.add(fee);
            amount = internalToHuman(amount, COIN_FRACS.NRG)
            addPending(dispatch,state,{hash:data.txHash,chain:'NRG',to:address,from:address,amount})
          }
        }
      })
      getNRGBalance(dispatch, state)
      getSpendableOutpoints(dispatch, state)
      dispatch({type:SET_LOADING,payload:false})
      done(null)
    }
    catch(err){
      console.log({err})
      dispatch({type:SET_LOADING,payload:false})
      done(err);
    };
  }
}
