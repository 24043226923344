var crypto = require("crypto");
var CoinKey = require('coinkey') //1.0.0
var bs58 = require('bs58');
var wallet = require('ethereumjs-wallet');
var convertHex = require('convert-hex');
const bip39 = require('bip39')
var sha256 = require('js-sha256');
var _ = require("lodash");

function keyBtcToEthInBytes(privKeyAsExportedByBitcoinDumpprivkey) {
    var decodedKey = bs58.decode(privKeyAsExportedByBitcoinDumpprivkey);
    var privKeyBytes = decodedKey.slice(1, decodedKey.length - 5);
    return privKeyBytes;
}

function privKeyToEthFormat(btcPrivateKey) {
    var privKeyBytes = keyBtcToEthInBytes(btcPrivateKey);
    var privKeyInEthFormat = new Buffer(privKeyBytes).toString('hex');
    return privKeyInEthFormat;
}

function getEthAddress(btcPrivateKey) {
    var myWallet = wallet.fromPrivateKey(new Buffer(keyBtcToEthInBytes(btcPrivateKey)));
    var addressInEthFormat = myWallet.getAddress();
    return '0x' + addressInEthFormat.toString('hex');
}

function getBtcPrivateKey(btcNet, ethAddress) {
    var addressArray = convertHex.hexToBytes(ethAddress);
    var partialResult = new Array();
    var result = new Array();

    if(btcNet === 'MAIN_NET') {
        partialResult.push(0x80);
    } else {
        partialResult.push(0xEF);
    }
    for (var i = 0;  i < addressArray.length; i++) {
        partialResult.push(addressArray[i]);
    }
    partialResult.push(0x01);
    var check = convertHex.hexToBytes(sha256(convertHex.hexToBytes(sha256(partialResult))));

    for (var i = 0;  i < partialResult.length; i++) {
        result.push(partialResult[i]);
    }
    for (var i = 0;  i < 4; i++) {
        result.push(check[i]);
    }

    return bs58.encode(result);
}

var eth = {
    privKeyToEthFormat: function (btcPrivateKey) {
        return privKeyToEthFormat(btcPrivateKey);
    },
    getEthAddress: function (btcPrivateKey) {
        return getEthAddress(btcPrivateKey);
    },
    getBtcPrivateKey: function (btcNet, ethAddress) {
        return getBtcPrivateKey(btcNet, ethAddress);
    }
};


async function btcWallet(mnem){

  //using a mnemonic instead to generate the wallet
  let secretPhrase = mnem;

  if(mnem === null){
    secretPhrase = bip39.generateMnemonic(256);
  }

  var seed = await bip39.mnemonicToSeed(secretPhrase);

  var h = crypto.createHash("sha256").update(seed).digest("hex");
  var buffer = new Buffer(h, 'hex')
  var ck = new CoinKey(buffer)

  var a = {
      privateKeyWF: ck.privateWif,
      bindingKey: ck.privateKey.toString('hex'),
      address: ck.publicAddress,
      mnemonic: secretPhrase
  }

  return a;
}

export const getETHAddress = (privKey)=> {
  try {
    var myWallet = wallet.fromPrivateKey(Buffer.from(privKey,'hex'));
    var addressInEthFormat = myWallet.getAddress();
    return '0x' + addressInEthFormat.toString('hex');
  }
  catch(err){
    return null;
  }
}

export const genWallet = async (mnem)=> {
    var wallet = await btcWallet(mnem);

    var ethAddress = eth.getEthAddress(wallet.privateKeyWF);

    var ethPrivateKey = eth.privKeyToEthFormat(wallet.privateKeyWF);

    var parts = _.chunk(ethAddress.slice(2), 4);

    var octs = _.chunk(ethAddress.slice(2), 2);

    var humanAddress =  parts.map(function(a){
        return a.join("");
    }).join("-");

    var macAddress =  octs.slice(0, 6).map(function(a){
        return a.join("");
    }).join("-");

    var payload = {
        btcWallet: wallet,
        ethWallet: {
            address: ethAddress,
            privateKey: "0x"+ethPrivateKey
        },
        networkAddress: macAddress.toUpperCase(),
        signature: humanAddress
    }

    return payload;
}
