var bitcoin = require('bitcoinjs-lib');
var request = require('superagent');
var axios = require('axios');

var BITCOIN_DIGITS = 8;
var BITCOIN_SAT_MULT = Math.pow(10, BITCOIN_DIGITS);

var providers = {
	/**
	 * Input: Requested processing speed. "fastest", "halfHour" or "hour"
	 * Output: Fee rate in Satoshi's per Byte.
	 */
	fees: {
		mainnet: {
			earn: function (feeName) {
				return request.get('https://bitcoinfees.earn.com/api/v1/fees/recommended').send().then(function (res) {
					return res.body[feeName + "Fee"];
				});
			}
		},
		testnet: {
			earn: function (feeName) {
				return request.get('https://bitcoinfees.earn.com/api/v1/fees/recommended').send().then(function (res) {
					return res.body[feeName + "Fee"];
				});
			}
		}
	},
	/**
	 * Input: Sending user's BitCoin wallet address.
	 * Output: List of utxo's to use. Must be in standard format. { txid, vout, satoshis, confirmations }
	 */
	utxo: {
		mainnet: {
			blockchain: function (addr) {
				return axios.get('https://blockchain.info/unspent?active=' + addr,{params:{cors:true}}).then(function (res) {
					return res.data.unspent_outputs.map(function (e) {
						return {
							txid: e.tx_hash_big_endian,
							vout: e.tx_output_n,
							satoshis: e.value,
							confirmations: e.confirmations
						};
					});
				});
			}
		},
		testnet: {
			blockexplorer: function (addr) {
				return request.get('https://testnet.blockexplorer.com/api/addr/' + addr + '/utxo').send().then(function (res) {
					return res.body.map(function (e) {
						return {
							txid: e.txid,
							vout: e.vout,
							satoshis: e.satoshis,
							confirmations: e.confirmations
						};
					});
				});
			}
		}
	},
	/**
	 * Input: A hex string transaction to be pushed to the blockchain.
	 * Output: None
	 */
	pushtx: {
		mainnet: {
			blockexplorer: function (hexTrans) {
				return request.post('https://blockexplorer.com/api/tx/send').send('rawtx=' + hexTrans);
			},
			blockchain: function (hexTrans) {
				return request.post('https://blockchain.info/pushtx').send('tx=' + hexTrans);
			},
			blockcypher: function (hexTrans) {
				return request.post('https://api.blockcypher.com/v1/btc/main/txs/push').send('{"tx":"' + hexTrans + '"}');
			}
		},
		testnet: {
			blockexplorer: function (hexTrans) {
				return request.post('https://testnet.blockexplorer.com/api/tx/send').send('rawtx=' + hexTrans);
			},
			blockcypher: function (hexTrans) {
				return request.post('https://api.blockcypher.com/v1/btc/test3/txs/push').send('{"tx":"' + hexTrans + '"}');
			}
		}
	}
}

//Set default providers
providers.fees.mainnet.default = providers.fees.mainnet.earn;
providers.fees.testnet.default = providers.fees.testnet.earn;
providers.utxo.mainnet.default = providers.utxo.mainnet.blockchain;
providers.utxo.testnet.default = providers.utxo.testnet.blockexplorer;
providers.pushtx.mainnet.default = providers.pushtx.mainnet.blockchain;
providers.pushtx.testnet.default = providers.pushtx.testnet.blockcypher;

function getTransactionSize (numInputs, numOutputs) {
	return numInputs*180 + numOutputs*34 + 10 + numInputs;
}

function getFees (provider, feeName) {
	if (typeof feeName === 'number') {
		return Promise.resolve(feeName);
	} else {
		return provider(feeName);
	}
}

export async function getFee (options) {
	var from = options.from;
	var amount = options.btc;
	var amtSatoshi = Math.floor(amount*BITCOIN_SAT_MULT);
	var bitcoinNetwork = bitcoin.networks.bitcoin;
	if (options.fee === null) options.fee = 'fastest';
	if (options.feesProvider === null) options.feesProvider = providers.fees[options.network].default;
	if (options.minConfirmations === null) options.minConfirmations = 0;
	if (options.utxoProvider === null) options.utxoProvider = providers.utxo[options.network].default;

	return Promise.all([
		getFees(options.feesProvider, options.fee),
		options.utxoProvider(from)
	]).then(async function (res) {
		var feePerByte = res[0];
		var utxos = res[1];
		//Setup inputs from utxos
		var tx = new bitcoin.TransactionBuilder(bitcoinNetwork);
		var ninputs = 0;
		var availableSat = 0;
		for (var i = 0; i < utxos.length; i++) {
			var utxo = utxos[i];
			if (utxo.confirmations >= options.minConfirmations) {
				tx.addInput(utxo.txid, utxo.vout);
				availableSat += utxo.satoshis;
				ninputs++;
				if (availableSat > amtSatoshi) break;
			}
		}
		if (availableSat < amtSatoshi) throw "You do not have enough in your wallet to send that much.";

		var change = availableSat - amtSatoshi;
		var fee = getTransactionSize(ninputs, change > 0 ? 2 : 1)*feePerByte / Math.pow(10,8);

		return fee
	});
}


export async function sendTransaction (options) {
	//Required
	if (options === null || typeof options !== 'object') throw "Options must be specified and must be an object.";
	if (options.from === null) throw "Must specify from address.";
	if (options.to === null) throw "Must specify to address.";
	if (options.btc === null) throw "Must specify amount of btc to send.";
	if (options.privKeyWIF === null) throw "Must specify the wallet's private key in WIF format.";

	//Optionals
	if (options.network === null) options.network = 'mainnet';
	if (options.fee === null) options.fee = 'fastest';
	if (options.feesProvider === null) options.feesProvider = providers.fees[options.network].default;
	if (options.utxoProvider === null) options.utxoProvider = providers.utxo[options.network].default;
	if (options.pushtxProvider === null) options.pushtxProvider = providers.pushtx[options.network].default;
	if (options.dryrun === null) options.dryrun = false;
	if (options.minConfirmations === null) options.minConfirmations = 0;

	var from = options.from;
	var to = options.to;
	var amount = options.btc;
	var amtSatoshi = Math.floor(amount*BITCOIN_SAT_MULT);
	var bitcoinNetwork = bitcoin.networks.bitcoin;

	return Promise.all([
		getFees(options.feesProvider, options.fee),
		options.utxoProvider(from)
	]).then(async function (res) {
		var feePerByte = res[0];
		var utxos = res[1];
		//Setup inputs from utxos
		var tx = new bitcoin.TransactionBuilder(bitcoinNetwork);
		var ninputs = 0;
		var availableSat = 0;
		for (var i = 0; i < utxos.length; i++) {
			var utxo = utxos[i];
			if (utxo.confirmations >= options.minConfirmations) {
				tx.addInput(utxo.txid, utxo.vout);
				availableSat += utxo.satoshis;
				ninputs++;
				if (availableSat > amtSatoshi) break;
			}
		}
		if (availableSat < amtSatoshi) throw "You do not have enough in your wallet to send that much.";

		var change = availableSat - amtSatoshi;
		var fee = getTransactionSize(ninputs, change > 0 ? 2 : 1)*feePerByte;
		if (fee > amtSatoshi) throw "BitCoin amount must be larger than the fee. (Ideally it should be MUCH larger)";
		tx.addOutput(to, amtSatoshi);
		if (change > 0) tx.addOutput(from, change - fee);
		var keyPair = bitcoin.ECPair.fromWIF(options.privKeyWIF, bitcoinNetwork);
		for (var i = 0; i < ninputs; i++) {
			try {
				tx.sign(i, keyPair);
			} catch(err){
				console.log({err})
			}
		}
		var msg = tx.build().toHex();
		if (options.dryrun) {
			return {msg};
		} else {
			let req = await request.post('https://api.blockcypher.com/v1/btc/main/txs/push').send('{"tx":"' + msg + '"}');
			if(req.statusText === "Created") return {msg};
			else return {msg}
		}
	});
}
