import {FETCHED_HISTORY,} from '../../types';
import {deletePending} from '../../ui/passport';
import axios from 'axios';

export function getLSKTransfers(done){
  return async function(dispatch,getState){
    try {
      for(let address of Object.keys(getState().passport.wallet.lsk)){
        let sent = await axios.get(`https://explorer.lisk.io/api/getTransactions?senderId=${address}`);
        let recieved = await axios.get(`https://explorer.lisk.io/api/getTransactions?recipientId=${address}`);

        let history = [];
        sent.data.transactions.concat(recieved.data.transactions).forEach(tx=>{
          let to = tx.recipientId;
          let from = tx.senderId;
          let timestamp = (1464109200000 + tx.timestamp*1000)/1000;
          let value = tx.amount / Math.pow(10,8)
          let color = to === address ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
          let type = "LSK";
          history.push({height:tx.height,from,timestamp,type,to,value,color,txHash:tx.id})
        })
        history = history.sort((a,b) => a.timestamp > b.timestamp )
        deletePending(history,getState(),dispatch)

        dispatch({
          type: FETCHED_HISTORY,
          payload:{history,type:'lsk',address}
        })
      }
    }
    catch(err){
      console.log({err})
    }
  }
}
