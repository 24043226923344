import {getWallet} from '../../helpers/wallet';
import {addPending} from './addPending';
import {SET_LOADING} from '../../types';

const lisk = require('lisk-elements');
var cryptography = require('@liskhq/lisk-cryptography');
const client = lisk.APIClient.createMainnetAPIClient();


function lskSign(dispatch,getState,{password,amount,address,to},done){
  try {
    let {privateKey} = getWallet(getState,address,'lsk',password)
    const transaction = lisk.transaction.transfer({
      amount: (amount*Math.pow(10,8)).toString(),
      recipientId: to,
      passphrase: privateKey,
    });

    client.transactions.broadcast(transaction).then((info)=>{
      done(null,transaction);
    }).catch((err)=>{
      console.log({err})
      done(err)
    });
  }
  catch(err){
    done(err);
    return;
  };
}

export function payLSK({password,amount,address,to},done){
	return function(dispatch,getState) {
    dispatch({type:SET_LOADING,payload:true})

    lskSign(dispatch,getState,{password,amount,address,to},(err,signedTx)=>{
      if(!err){
        let {privateKey} = getWallet(getState,address,'lsk',password)
        addPending(dispatch,getState(),{hash:signedTx.id,chain:'LSK',to,from:address,amount})
      }
      dispatch({type:SET_LOADING,payload:false})
      done(err,signedTx);
    })
	}
}
