import {web3} from '../../config';
var Tx = require('ethereumjs-tx').Transaction;

const getNonce = (from) => {
  return new Promise((resolve, reject) => {
    web3.eth.getTransactionCount(from,'pending', (error, result) => {
      if(error) reject(error);
      resolve(web3.utils.toHex(result));
    })
  })
}

export const getGasPrice = () => {
  return new Promise((resolve, reject) => {
    web3.eth.getGasPrice((error, result) => {
      if(error) reject(error);
      resolve(result*2);
    })
  })
}

const sendRawTransaction = (tx,done) => {
  web3.eth.sendSignedTransaction(tx)
  .on('transactionHash',()=>done(null,tx))
  .on('error',(err)=>done(err))
}

export const signTransaction = ({from,to,value,data,privateKey},done) => {
  Promise.all([getNonce(from), getGasPrice()]).then(values => {
    return({
      gasLimit: web3.utils.toHex(53000),
      gasPrice: web3.utils.toHex(values[1]),
      nonce: values[0],
      to,
      value,
      data
    });
  }).then((rawTx)=> {
      let tx = new Tx(rawTx, {'chain':'mainnet'});
      tx.sign(Buffer.from(privateKey, 'hex'));
      done(null,tx,'0x'+tx.serialize().toString('hex'));
  }).catch(err=>{
      done(err);
  });
}

export const submitTransaction = (args,dispatch,done) => {
  signTransaction(args,(err,tx,serializedTx)=>{
    if(!err){
      sendRawTransaction(serializedTx,(err,receipt)=>{
        if(!err)done(null,tx.hash(true).toString('hex'))
        else done(err)
      })
    }
    else done(err)
  })
}
