import {
  humanToInternalAsBN,
  COIN_FRACS,
  internalToBN,
  internalBNToHuman,
  Currency,
  CurrencyInfo
} from 'bc-sdk/dist/utils/coin'
var Web3 = require("web3");
const BN = require('bn.js')

//URLS
const ropsten_url = 'https://ropsten.infura.io/v3/ca4c368803c347699a5d989cd367c0a6';
export const mainnet_url = 'https://mainnet.infura.io/v3/ca4c368803c347699a5d989cd367c0a6';
export const multiverseUrl = "http://multiverse.blockcollider.org/#/blocks/latest"

//WALLET
export const initWallet = JSON.parse(window.localStorage.getItem('bc-wallet'));

export const fee = humanToInternalAsBN('0.0006806',COIN_FRACS.NRG);

//SETTINGS
export const settings = JSON.parse(window.localStorage.getItem('settings')) ? JSON.parse(window.localStorage.getItem('settings')) : {
  autoTrader:false,
  shiftMaker: 0,
  shiftTaker: 0,
  minOrder:10,
  deposit:500,
  settlement:1000,
  hostname: 'http://localhost',
  port: '3000',
  scookie: 'testCookie123'
}

// TODO migration for invalid hostname from previous version, can be removed after a while
if (!settings.hostname.startsWith('http')) {
  settings.hostname = `http://${settings.hostname}`
}

//PAIRS
export const assets = ['NRG','EMB','L-EMB','USDT', 'DAI','BTC','ETH','NEO','WAV','LSK']

export const txURL = {
  // NRG:'http://borderless.ngrok.io/#/tx/',
  EMB:'https://etherscan.io/tx/',
  BTC:'https://blockchair.com/bitcoin/transaction/',
  ETH:'https://etherscan.io/tx/',
  DAI:'https://etherscan.io/tx/',
  USDT:'https://etherscan.io/tx/',
  NEO:'https://neoscan.io/transaction/',
  WAV:'https://wavesexplorer.com/tx/',
  LSK:'https://explorer.lisk.io/tx/'
}

export const blockURL = {
  // NRG:'http://multiverse.blockcollider.com/block/',
  BTC:'https://blockchair.com/bitcoin/block/',
  ETH:'https://etherscan.io/block/',
  NEO:'https://neoscan.io/block/',
  WAV:'https://wavesexplorer.com/blocks/',
  LSK:'https://explorer.lisk.io/block/'
}

export const colors = {
  green:'#64ad62',
  red:'#de7272',
  'L-EMB':"#7f6cbe",
  EMB:"#6B459C",
  NRG:'#2a79ff',
  BTC:"#FBB02F",
  ETH:"#627eea",
  USDT:'#27a17b',
  DAI:'#ffc926',
  NEO:"#5ABD02",
  WAV:"#0355ff",
  LSK:"#073878"
}

//COIN DENOMINATIONS
export const fromUnit = (type,amount) => {
  try {
    return Currency.fromMinimumUnitToHuman(
      type, amount, CurrencyInfo[type].minUnit
    )
  }
  catch(err){
    return amount
  }
}

export const multiplyCurrency = (curr1,curr2) => {
  let unit1 = Currency.toMinimumUnitAsStr(
    curr1.type.toLowerCase(), curr1.amount, CurrencyInfo[curr1.type.toLowerCase()].humanUnit
  )
  let unit2 = Currency.toMinimumUnitAsStr(
    curr2.type.toLowerCase(), curr2.amount, CurrencyInfo[curr2.type.toLowerCase()].humanUnit
  )
  let mult = new BN(unit1).mul(new BN(unit2))
  mult = Currency.fromMinimumUnitToHuman(
    curr1.type.toLowerCase(), mult.toString(), CurrencyInfo[curr1.type.toLowerCase()].minUnit
  )
  mult = Currency.fromMinimumUnitToHuman(
    curr2.type.toLowerCase(), mult, CurrencyInfo[curr2.type.toLowerCase()].minUnit
  )
  return mult
}

export const toUnit = (type,amount) => {
  amount = parseFloat(amount)
  if(type === 'btc' || type === 'wav' || type === 'lsk' ) return amount * Math.pow(10,8)
  else if(type === 'eth' || type === 'nrg' || type === 'dai' || type === 'emb') return amount * Math.pow(10,18)
  else if(type === 'usdt') return amount * Math.pow(10,6)
  else return amount
}

//WEB3
export const web3 = new Web3(new Web3.providers.HttpProvider(mainnet_url));

// CONTRACTS
export const LEMB_ADDRESS = "0xcA8FdddC496E028708Cf9d5aad0031c5b37b760d";
export const EMB_ADDRESS = "0x28B94F58B11aC945341329dBf2e5EF7F8Bd44225";
export const LEASE_ADDRESS = "0x32CD3B832075195164E7dDcE2D1C580Ce80F029b";
export const EXISTING_LEASE_ADDRESS = "0xb19188528FD972318AAE16C739849627B758474a";
export const EMB_EXCHANGE_ADDRESS = "0x41538bef42ad5D7683D2e6d4b6CeefEb29F4F036";
export const VANITY_EXCHANGE_ADDRESS = "0x42E62cA948354F4B63d776a03d87C98F2c71Be42";
export const USDT_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
export const DAI_ADDRESS = "0x6b175474e89094c44da98b954eedeac495271d0f";

export const EMB_ABI = require('./contracts/Emblem.json').abi;
export const DAI_ABI = require('./contracts/DAI.json');
export const USDT_ABI = require('./contracts/USDT.json');

let options = {gasLimit:2000000000000,gasPrice:20000}
export const DAI = new web3.eth.Contract(DAI_ABI,DAI_ADDRESS,options);
export const USDT = new web3.eth.Contract(USDT_ABI,USDT_ADDRESS,options);
export const EMB = new web3.eth.Contract(EMB_ABI,EMB_ADDRESS,options);
