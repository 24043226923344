import {getWallet} from '../../helpers/wallet';
import {addPending} from './addPending';
import {SET_LOADING} from '../../types';
import { sendTx, BASE_BLOCK_SIZE } from '../../helpers/bc';
import { getNRGBalance, getSpendableOutpoints } from '../../passport/get';

import {
  createNRGTransferTransaction
} from 'bc-sdk/dist/transaction'
import BN from 'bn.js'

import {
  COIN_FRACS,
  Currency,
  CurrencyInfo,
  humanToInternalAsBN,
  internalBNToHuman,
  internalToBN,
} from 'bc-sdk/dist/utils/coin'

import {
  convertProtoBufSerializedBytesToBuffer,
} from 'bc-sdk/dist/utils/protoUtil'


function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function payNRG({password,amount,address,to,fee},done){
  return async function(dispatch,getState){
    try {
      dispatch({type:SET_LOADING,payload:true})

      if (!fee) fee = 0
      let state = getState()

      let { privateKey: bcPrivateKeyHex} = getWallet(getState,address, 'nrg', password)
      let spendableOutpointsList = getState().passport.spendableOutpoints[address]
      if(!spendableOutpointsList ||spendableOutpointsList.length === 0){
        await getSpendableOutpoints(dispatch, state)
        spendableOutpointsList = getState().passport.spendableOutpoints[address]
      }
      if(!spendableOutpointsList ||spendableOutpointsList.length === 0){
        dispatch({type:SET_LOADING,payload:false})
        done('No UTXOs To Spend')
        return
      }
      await timeout(10)
      const tx = createNRGTransferTransaction(spendableOutpointsList, address, bcPrivateKeyHex, to, amount.toString(),fee.toString(), true)

      let sum = 0;
      tx.getInputsList().map((input)=>{
        sum+=input.serializeBinary().length
      })

      const txSize = tx.serializeBinary().length

      let maxSize = Math.floor(BASE_BLOCK_SIZE * 2 / 3)
      if (maxSize <= txSize) {
        dispatch({type:SET_LOADING,payload:false})
        done('Please Consolidate Your UTXOs')
        return
      }
      else {
        let data = await sendTx(state.settings.settings, tx)
        if(data.txHash){
          getNRGBalance(dispatch, state)
          getSpendableOutpoints(dispatch, state)
          addPending(dispatch,state,{hash:data.txHash,chain:'NRG',to,from:address.toLowerCase(),amount})
          dispatch({type:SET_LOADING,payload:false})
          done(null,data)
          return
        }
        else {
          console.log({data})
          dispatch({type:SET_LOADING,payload:false})
          done('Could not transfer NRG')
          return
        }
      }
    }
    catch(err){
      dispatch({type:SET_LOADING,payload:false})
      done(err);
    };
  }
}
