import {getWallet} from '../../helpers/wallet';
import {addPending} from './addPending';
import {SET_LOADING} from '../../types';

const WavesAPI = require('@waves/waves-api');
const { transfer,broadcast } = require('@waves/waves-transactions')

function wavSign(dispatch,getState,{password,amount,address,to},done){
  let seed = "";
  try {
    let {privateKey} = getWallet(getState,'wav',password)
    let signed = transfer({
        recipient: to,
        amount: amount * Math.pow(10,8),
        fee: 100000,
    },privateKey.phrase);
    done(null,signed);
  }
  catch(err){
    done(err);
  };
}

export function payWAV({password,amount,address,to},done){
  return function(dispatch,getState) {
    dispatch({type:SET_LOADING,payload:true})
    wavSign(dispatch,getState,{password,amount,address,to},(err,signedTx)=>{
      if(signedTx){
        let nodeUrl = 'https://nodes.wavesplatform.com'
        let {privateKey} = getWallet(getState,'wav',password)
        addPending(dispatch,getState(),{hash:signedTx.id,chain:'WAV',to,from:address,amount})
        dispatch({type:SET_LOADING,payload:false})
        broadcast(signedTx, nodeUrl).then((resp) => {
          done(null,resp)
        })
      }
      else {
        dispatch({type:SET_LOADING,payload:false})
        done(err)
      }
    })
  }
}
