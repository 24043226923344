import {FETCHED_HISTORY,} from '../../types';
import {deletePending} from '../../ui/passport';
import axios from 'axios';

export function getNEOTransfers(done){
  return async function(dispatch,getState){
    try {
      for(let address of Object.keys(getState().passport.wallet.neo)){
        let response = await axios.get(`https://api.neoscan.io/api/main_net/v1/get_address_abstracts/${address}/1`)
        if(response && response.data && response.data.entries){
          let history = [];
          response.data.entries.forEach(tx => {
            if(tx.asset === "c56f33fc6ecfcd0c225c4ab356fee59390af8560be0e930faebe74a6daff7c9b"){
              let timestamp = tx.time
              let type = "NEO";
              let from = tx.address_from;
              let to = tx.address_to;
              let color = to === address ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
              let value = tx.amount;
              history.push({height:tx.block_height,from,timestamp,type,to,value,color,txHash:tx.txid})
            }
          })

          deletePending(history,getState(),dispatch)

          dispatch({
            type: FETCHED_HISTORY,
            payload:{history,type:'neo',address}
          })
        }
      }
    }
    catch(err){
      console.log({err})
    }
  }
}
