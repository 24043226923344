import React, { Component } from 'react';

class ShowGen extends Component {
  constructor(){
    super();
    this.state = {show: false}
  }
  renderCard({type,address,priv,mnem,networkAddress,signature}){
    return (
      <div className="row " style={{textAlign:'left',marginLeft:'0'}} >

          {address &&
            <div style={{textAlign:'left'}}>
              <p style={{ color: "grey" }}>Address:</p>
              <p style={{ color: "black", marginBottom:'15px'}}>{address}</p>
              
                <i
                  className="material-icons"
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    color: "grey",
                    fontSize: "20px"
                  }}
                  onClick={this.toggle.bind(this)}
                >
                  remove_red_eye
                </i>
                <p style={{ color: "grey", float: "left" }}>Mnemonic Phrase
                </p>
                <p style={{ color: "black",wordBreak:'break-word',height:'80px',fontSize:'12px' }}>
                  {this.state.show ? mnem : "*******************"}
                </p>

            </div>
          }

      </div>
    )
  }
  toggle(){
    this.setState({show:!this.state.show});
  }
  render() {
    let mnem = this.props.wallet.btcWallet.mnemonic;
    let eth = {
      address: this.props.wallet.ethWallet.address,
      priv: this.props.wallet.ethWallet.privateKey,
      networkAddress: this.props.wallet.networkAddress,
      signature: this.props.wallet.signature,
      mnem : this.props.wallet.btcWallet.mnemonic
    }
    return (
      <div className='row' style={{margin:0}}>
        {this.renderCard(eth)}
        <div className='row' style={{margin:0}}></div>
      </div>
    )
  }
}

export default ShowGen;
