import {FETCHED_BALANCE} from '../../types';
import axios from 'axios';

export function getNEOBalanceDispatch(){
  return function(dispatch,getState){
    getNEOBalance(dispatch,getState())
  }
}

export async function getNEOBalance(dispatch,state){
  for(let address of Object.keys(state.passport.wallet.neo)){
    let response = await axios.get(`https://api.neoscan.io/api/main_net/v1/get_balance/${address}`)
    if(response && response.data && response.data.balance){
      let balance = response.data.balance.filter((token)=>{return (token.asset === 'NEO')})
      dispatch({
        type:FETCHED_BALANCE,
        payload: {address,balance:balance.length === 1 ? balance[0].amount : 0, asset:'neo'}
      });
    }
  }
}
