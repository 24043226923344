import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css/dist/js/materialize.min.js'
import $ from 'jquery';
import { Link } from 'react-router-dom';
import noUiSlider from 'nouislider';
import {updateSettings} from '../../actions/ui/settings';
import {settings} from '../../config.js'
import Modal from '../helper/modal';
import callBC from '../../actions/helpers/bc';

class Settings extends Component {
  state = this.props.settings
  componentDidMount(){
    let _this = this;
    setTimeout(()=>{
      if((_this.props.socket.connected === false || _this.props.socket.no_rpc)) {
        _this.openModal()
      }
    },5000)
  }
  componentDidUpdate(nextProps){
    if(nextProps.loggedIn !==this.props.loggedIn){

    }
  }
  async saveModal(){
    let settings = this.state;
    let _this = this;
    this.props.networksettings.forEach(({id})=>{
      settings[id] = $(`#${id}`)[0].value.trim();
    });
    if(settings.hostname.endsWith('/')){
      settings.hostname = settings.hostname.substring(0,settings.hostname.length-1)
    }
    if(settings.hostname.includes('https') && settings.scookie.length === 0){
      M.toast({html: 'Secure Cookie must be set for HTTPS URL',classes:"red",displayLength:3000});
    }
    else {
      callBC(this.props.settings,'getBalance',[this.props.address]).then((data)=>{
        window.localStorage.setItem('settings',JSON.stringify(settings));
        _this.props.updateSettings(settings,()=>{});
        var instance = M.Modal.getInstance(document.querySelectorAll('#modalSettings')[0]);
        instance.close()
      }).catch((err)=>{
        if((_this.props.socket.connected === false || _this.props.socket.no_rpc))M.toast({html: 'Miner URL Incorrect',classes:"red",displayLength:3000});
      })
    }
  }
  renderModal(){
    let component = this.network();
    return (
      <Modal id='modalSettings' classes='modal modalOrders black-text' title='Settings' buttonText={"Save"} buttonAction={this.saveModal.bind(this)} noClose={true} noIcon={(this.props.socket.connected === false || this.props.socket.no_rpc)} component={component} logout={true} />
    )
  }
  network(){
    let settings = this.props.networksettings

    let items = settings.map(({title,id,max,min,background}, i)=>{
      return (
        <div className='row' key={i} style={{marginBottom:'5px',background}}>
          <div style={{}} className='col s6'>
            <h6 style={{fontSize:'1rem',float:'right',lineHeight:'38.5px'}} className='black-text'> <b>{title} </b> </h6>
          </div>
          <div className='col s6'>
            <input id={id} type={max ? 'number' : 'text'} min={min} max={max} className='black-text' style={{marginTop:'12px',fontFamily:'Gilmer-Light',letterSpacing: '0.5px'}}/>
          </div>
        </div>
      )
    });
    return (
      <div className='row' id="network">
        {items}
      </div>
    )
  }
  openModal(){
    let _this = this;
    if(document.querySelectorAll('#modalSettings')[0]){
      var instance = M.Modal.getInstance(document.querySelectorAll('#modalSettings')[0]);
      if(instance) {
        instance.open();
      }
      setTimeout(()=>{
        _this.props.networksettings.forEach(({id})=>{
          $(`#${id}`)[0].value = _this.state[id];
        });
      },100);
    }
  }
  render(){
    return (
      <li>
        <Link to="#">
          <i className="material-icons icon" onClick={this.openModal.bind(this)}>settings</i>
        </Link>
        {this.renderModal()}
      </li>
    )
  }
}


function mapStateToProps(state,props) {
  let networksettings = [
    // {title:"Order Lifespan (# of BC BLocks):",id:'deposit',width:'100%',max:1000,min:10},
    // {title:"Settlement Length (# of BC BLocks):",id:'settlement',width:'100%',max:1000,min:10},
    {title:"BC Miner URL",id:'hostname',width:"100%",background: '#80808030'},
    {title:"BC Miner Port",id:'port',width:"100%"},
    {title:"BC Miner Secure Cookie", id:'scookie', width:"100%",background: '#80808030'},
  ];

  return {
    address: Object.keys(state.passport.wallet.emb)[0],
    loggedIn: state.passport.wallet.emb && state.passport.wallet.nrg && state.passport.wallet['l-emb'] && state.passport.wallet['emb'] && !state.passport.wallet.emb.address,
    socket: state.socket,
    settings: state.settings.settings,
    networksettings
  };
}


export default connect(mapStateToProps, {updateSettings,
  // collateralizeFilter
})(Settings);
