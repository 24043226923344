import {FETCHED_HISTORY,LAST_NRG_TX} from '../../types';
import {deletePending} from '../../ui/passport';
import callBC from '../../helpers/bc.js';
import {getSpendableOutpoints} from '../../passport/get/nrg';

const { blake2bl } = require('bc-sdk/dist/utils/crypto')

export function getNRGTransfers(done){
  return async function(dispatch,getState){
    for(let address of Object.keys(getState().passport.wallet.nrg)){
      let data = await callBC(getState().settings.settings,'getTransfers',[address,20,'latest'])
      let blake_address = blake2bl(blake2bl(address.toLowerCase()) + address.toLowerCase())

      if(data && data.result && data.result.transfersList){
        let history = data.result.transfersList.map((h)=>{
          if(h.from === blake_address) h.from = address.toLowerCase()
          h.color = h.to === address.toLowerCase() ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
          if(h.from === h.to) h.color = 'grey';
          h.type = "NRG";
          h.value = h.amount
          if(h.from === 'coinbase') h.from = 'Mined';
          return h;
        }).reverse().filter((h)=>{
          return h.from !==''
        })

        // deletePending(history,getState(),dispatch)
        if(history.length > 0){
          if(getState().transfer_history.last_tx[address] !==history[history.length-1].txHash){
            // getSpendableOutpoints(dispatch, getState())
          }
          dispatch({
            type: LAST_NRG_TX,
            payload:{address,history:history[history.length-1].txHash}
          })
        }

        dispatch({
          type: FETCHED_HISTORY,
          payload:{history,type:'nrg',address}
        })
      }
    }
  }
}
