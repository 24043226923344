import React, { Component } from 'react';
import { connect } from 'react-redux';
import {multiverseUrl,blockURL,assets} from '../../config.js'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js'

class Portfolio extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(){
    super();
    this.state = {open:false};
  }
  onMouseEnter(){
    this.setState({open:true})
  }
  onMouseLeave(){
    this.setState({open:false})
  }
  renderChildBlocks(){
    let downloads = [
      {key:'Mac' ,url:'https://aws-website-wwwblockcollidercom-xzf3z.s3.amazonaws.com/borderless_portfolio/Borderless+Portfolio-1.0.0.dmg'},
      {key: 'PC', url: 'https://aws-website-wwwblockcollidercom-xzf3z.s3.amazonaws.com/borderless_portfolio/Borderless+Portfolio+Setup+1.0.0.exe'},
      {key: 'Linux', url:'https://aws-website-wwwblockcollidercom-xzf3z.s3.amazonaws.com/borderless_portfolio/Borderless+Portfolio-1.0.0.AppImage'}
    ]
    return downloads.map(({url,key},i)=>{
      let borderBottom = i !==downloads.length -1 ? '1px solid grey' : '';
      return (
        <a key={i} className='col s12' style={{borderBottom}} href={url}>
          <div className='download'>
            <span style={{textOverflow:'ellipsis',overflow:'hidden'}}>{key}</span>
          </div>
        </a>
      )
    })
  }
  copyFrom(address){
    var textField = document.createElement('textarea')
    textField.innerText =  address
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    M.toast({html: 'Copied Address',classes:"black",displayLength:3000});
  }
  render() {
    let color = this.props.disconnected ? '#f44336' : 'white';
    let display = this.state.open ? '' : 'none';
    let passportDisplay = this.props.location.pathname === '/' ? 'white' : 'grey';

    return (
      <li onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} >
        <Link className='rightLink' to="/" style={{color:'grey',color:passportDisplay}} onClick={this.onMouseLeave.bind(this)}>
          download
        </Link>
        <div className='downloads' style={{display,background:'rgba(39, 39, 39)'}}>
          {this.renderChildBlocks()}
        </div>
      </li>
    )
  }
}

function mapStateToProps(state,props) {

}

//
export default connect(mapStateToProps, {})(withRouter(props => <Portfolio {...props}/>));
