import {FETCHED_BALANCE} from '../../types';
import axios from 'axios';

export function getBTCBalanceDispatch(){
  return function(dispatch,getState){
    getBTCBalance(dispatch,getState())
  }
}


export async function getBTCBalance(dispatch,state) {
  let backups = [tryBlockCypher,tryBlockchainInfo];
  for(let address of Object.keys(state.passport.wallet.btc)){
    let success = false
    //try all backup functions, if any of them are succesfull, break out of the loop
    for(let func of backups){
      success = await func(address,dispatch)
      if(success) break;
    }
  }
}

async function tryBlockchainInfo(address,dispatch) {
  try {
    let response = await axios.get(`https://blockchain.info/balance?active=${address}`,{params:{cors:true}})
    if(response && response.data && response.data.final_balance){
      dispatch({
        type:FETCHED_BALANCE,
        payload: {balance:response.data.final_balance / Math.pow(10,8),address,asset:'btc'}
      });
      return true;
    }
    return false
  }
  catch(err){
    return false
  }
}

async function tryBlockCypher(address, dispatch){
  try{
    let response = await axios.get(`https://api.blockcypher.com/v1/btc/main/addrs/${address}`,{params:{cors:true}})
    if(response && response.data && response.data.final_balance){
      dispatch({
        type:FETCHED_BALANCE,
        payload: {balance:response.data.final_balance / Math.pow(10,8),address,asset:'btc'}
      });
      return true
    }
    return false
  }
  catch(err){
    return false
  }
}
