//Passport
export const FETCHED_NRG = "fetched_nrg";
export const FETCHED_BALANCE = 'fetched_balance';
export const FETCHED_SPENDABLE_OUTPOINTS = 'fetched_spendable_outpoints'

export const FETCHED_FEE = 'fetched_fee';

export const WALLET_SET = "wallet_set";
export const REMOVE_WALLET = 'remove_wallet';
export const REMOVE_ASSET = 'remove_asset';

//Pending
export const SET_PENDING_TRANSACTIONS = "pending_transactions";
export const OVERRIDE_PENDING_TRANSACTIONS = "override_transactions";

//Settings
export const SET_SETTINGS = 'set_settings';

//Socket
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const BLOCKS_MINED_ADD = "BLOCKS_MINED_ADD";
export const RETRIEVING_DATA = 'retreiving_data';
export const CHANGE_SOCKET = 'change_socket';
export const SOCKET_CREATED = 'socket_created';
export const NO_RPC = 'no_rpc';

//Transfer History
export const FETCHED_HISTORY = "fetched_history";
export const LAST_NRG_TX = 'last_nrg_tx';

//Passport UI
export const SET_LOADING='set_loading';
export const SET_PASSPORT_FEE = "set_passport_fee";
export const SET_PASSPORT_AMOUNT = "set_passport_amount";
export const SET_PASSPORT_ASSET_VIEW = "set_passport_asset_view";


//SCHEDULED Events
export const NEW_EVENT = 'new_event';
export const REMOVE_EVENT = 'remove_event';


//PRICE
export const FETCHED_DAI_PRICE = "fetched_dai_price";
export const FETCHED_BTC_PRICE = "fetched_btc_price";
export const FETCHED_ETH_PRICE = "fetched_eth_price";
export const FETCHED_NEO_PRICE = "fetched_neo_price";
export const FETCHED_WAV_PRICE = "fetched_wav_price";
export const FETCHED_LSK_PRICE = "fetched_lsk_price";
export const FETCHED_NRG_PRICE = "fetched_nrg_price";
export const FETCHED_EMB_PRICE = "fetched_emb_price";
export const FETCHED_USDT_PRICE = "fetched_usdt_price";
export const FETCHED_LEMB_PRICE = "fetched_lemb_price";
