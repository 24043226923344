import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import reduxThunk from 'redux-thunk';
import Header from './components/Header/index.jsx';
import Passport from './components/Passport/';
import reducers from './reducers';
import { init as initSocketReducer, initPrices } from './reducers/socket';
import {settings} from './config';
import './main.css';

// const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
// const store = createStoreWithMiddleware(reducers);
const store = createStore(reducers, composeWithDevTools({trace:true})(
  applyMiddleware(reduxThunk),
));

//used to retrieve blocks and call get balance and get orders at each block height
initSocketReducer({hostname:settings.hostname,port:settings.port},store.dispatch,store)
initPrices(store.dispatch)

ReactDOM.render(
  <Provider store={store} history={ReactDOM.hashHistory}>
    <Router>
      <div>
        <Header/>
        <main>
            <Switch>
              <Route exact path='/' render={(props) => <Passport {...props} />}/>
            </Switch>
        </main>
      </div>
    </Router>
  </Provider>
, document.getElementById('root')
);



// <div className='row'>
//   <div className='col s12' style={{color:'grey',fontSize:'12px',paddingBottom:'20px'}}>
//     <hr style={{color:'grey',borderColor:'grey',marginTop:'10px'}}/>
//     © 2019 - Borderless. All Rights Reserved.
//   </div>
// </div>
