import {FETCHED_BALANCE} from '../../types';
import axios from 'axios';

export function getLSKBalanceDispatch(){
  return function(dispatch,getState){
    getLSKBalance(dispatch,getState())
  }
}

export async function getLSKBalance(dispatch,state){
  for(let address of Object.keys(state.passport.wallet.lsk)){
    let response = await axios.get(`https://explorer.lisk.io/api/getAccount?address=${address}`)
    if(response.data && response.data.success){
      dispatch({
        type:FETCHED_BALANCE,
        payload: {balance:response.data.balance / Math.pow(10,8),asset:'lsk',address}
      });
    }
  }
}
