import {FETCHED_BALANCE} from '../../types';
import {USDT} from '../../../config'

export function getUSDTBalanceDispatch(){
  return function(dispatch,getState){
    getUSDTBalance(dispatch,getState())
  }
}

export async function getUSDTBalance(dispatch,state){
  for(let address of Object.keys(state.passport.wallet.usdt)){
    USDT.methods.balanceOf(address.toLowerCase()).call().then((result)=>{
      dispatch({
        type: FETCHED_BALANCE,
        payload: {balance:result/Math.pow(10,6),asset:'usdt', address}
      })
    })
  }
}
