import {genWallet} from '../../helpers/simpleWallet';

export function genBTCWallet(done){
  return async function(dispatch,getState) {
    try {
      let wallet = (await genWallet(null)).btcWallet
      wallet.privateKey = wallet.privateKeyWF
      wallet.type = 'BTC';
      done(wallet)
    }
    catch(err){
      done(err);
    }
  }
}
