import {FETCHED_FEE} from '../../types';

export function getNRGFee(amount,address,done){
	return function(dispatch,getState) {
		// console.log({amount,address})
    dispatch({
      type:FETCHED_FEE,
      payload: 16600000000000 * 41 / Math.pow(10,18) 
    })
		if(done) done();

  }
}
