import React from 'react';

// 404
export default (field) => {

  if(field.items){
    var items = field.items.map((h,i) => {
      let className = field.classes ? field.classes[i] : '';
      return (
        <th key={i} className={className} style={{fontSize:field.fontSize,lineHeight:field.lineHeight}}>{h}</th>
      );
    });
  }
  return(
    <div className="col s12" style={{padding: 0}}>
      <table className="white-text order-book striped" style={{tableLayout:"fixed"}}>
        <thead>
          <tr>
              {items}
          </tr>
        </thead>
      </table>
    </div>
  )
}
