import {web3, EMB} from '../../../config'
import {FETCHED_HISTORY,} from '../../types';
import {deletePending} from '../../ui/passport';

export function getEMBTransfers(done){
  return async function(dispatch,getState) {

    try {
      for(let address of Object.keys(getState().passport.wallet.emb)){
        let events = await EMB.getPastEvents('Transfer',{fromBlock:0,toBlock:'latest',filter:{from:[address.toLowerCase()]}})
        let events2 = await EMB.getPastEvents('Transfer',{fromBlock:0,toBlock:'latest',filter:{to:[address.toLowerCase()]}})

        let mytransfers = [];
        events.concat(events2).forEach(({blockNumber,returnValues,transactionHash})=>{
          if(returnValues.from.toUpperCase() === address || returnValues.to.toUpperCase() === address){
            returnValues.color = returnValues.to.toUpperCase() === address ? `rgb(101, 173, 97)` : `rgb(222, 114, 114)`;
            returnValues.height = blockNumber;
            returnValues.value = returnValues.value / Math.pow(10,8);
            returnValues.txHash = transactionHash;
            mytransfers.push(returnValues);
          }
        });

        let promises = mytransfers.map(p=>{
          return new Promise((resolve,reject)=>{
            web3.eth.getBlock(p.height).then((block)=>{
              p.timestamp = block.timestamp;
              p.type = "EMB";
              resolve(p);
            })
          });
        });

        Promise.all(promises).then(history => {
          history.sort((a,b) => b.timestamp - a.timestamp )

          deletePending(history,getState(),dispatch)

          dispatch({
            type: FETCHED_HISTORY,
            payload:{history,type:'emb',address}
          })
        });
      }
    }
    catch(err){
      console.log({err})
    }
  }
}
