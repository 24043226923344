import {
  FETCHED_HISTORY,
  LAST_NRG_TX,
  REMOVE_WALLET,
  REMOVE_ASSET
} from '../actions/types';
import {differenceWith} from 'ramda';
import M from 'materialize-css/dist/js/materialize.min.js'

let nrg = window.localStorage.getItem('nrg-history') ? JSON.parse(window.localStorage.getItem('nrg-history')) : {}
let last_tx = {}
if(nrg){
  Object.keys(nrg).map((key)=>{
    last_tx[key] = nrg[key][nrg[key].length -1]
  })
}

let initialState =  {
  nrg,
  emb: {},
  btc: {},
  eth: {},
  neo: {},
  wav: {},
  dai: {},
  usdt:{},
  'l-emb':{},
  lsk: {},
  last_tx: {}
}

export default function(state: Object = initialState, action) {
  switch(action.type) {
    case LAST_NRG_TX :
      let newstate = state;
      newstate.last_tx[action.payload.address] = action.payload.history
      return {...newstate}
    case FETCHED_HISTORY:
      newstate = state;
      if(action.payload.type == 'nrg'){
        newstate.nrg[action.payload.address] = action.payload.history
        window.localStorage.setItem(`nrg-history`,JSON.stringify(newstate.nrg));
        return {...newstate}
      }
      else {
        newstate[action.payload.type][action.payload.address] = action.payload.history
        return {...newstate}
      }
    case REMOVE_ASSET:
      newstate = state;
      newstate[action.payload.asset][action.payload.address] = {}
      return {...newstate}
    case REMOVE_WALLET:
      return {
        nrg: {},
        emb: {},
        btc: {},
        eth: {},
        neo: {},
        usdt: {},
        dai: {},
        wav: {},
        lsk: {}}
    default:
      return state;
  }
}
