import {getBTCTransfers} from './btc';
import {getETHTransfers} from './eth';
import {getNEOTransfers} from './neo';
import {getWAVTransfers} from './wav';
import {getLSKTransfers} from './lsk';
import {getNRGTransfers} from './nrg';
import {getEMBTransfers} from './emb';
import {getDAITransfers} from './dai';
import {getUSDTTransfers} from './usdt';

let lastCalled = Date.now() - 60000;

export function getAllTransfers(dispatch,getState){
  if(getState().passport.wallet){
    // if(getState().passport.wallet.emb) getEMBTransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.usdt) getUSDTTransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.dai) getDAITransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.emb) getNRGTransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.btc && Date.now() - lastCalled > 60000) getBTCTransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.eth) getETHTransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.neo) getNEOTransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.wav) getWAVTransfers(()=>{})(dispatch,getState)
    if(getState().passport.wallet.lsk) getLSKTransfers(()=>{})(dispatch,getState)

    lastCalled = Date.now()
  }
}
export {
  getNEOTransfers,
  getBTCTransfers,
  getETHTransfers,
  getLSKTransfers,
  getWAVTransfers,
  getNRGTransfers,
  getEMBTransfers,
  getDAITransfers,
  getUSDTTransfers
}
