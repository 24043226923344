import {FETCHED_BALANCE} from '../../types';
import {DAI} from '../../../config'

export function getDAIBalanceDispatch(){
  return function(dispatch,getState){
    getDAIBalance(dispatch,getState())
  }
}

export async function getDAIBalance(dispatch,state){
  for(let address of Object.keys(state.passport.wallet.dai)){
    DAI.methods.balanceOf(address.toLowerCase()).call().then((result)=>{
      dispatch({
        type: FETCHED_BALANCE,
        payload: {balance:result/Math.pow(10,18),asset:'dai', address}
      })
    })
  }
}
