import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { setBSECWallet } from "../../../actions/passport/set";
import {
  genWallet,
  getETHAddress
} from "../../../actions/helpers/simpleWallet";
import M from "materialize-css/dist/js/materialize.min.js";
import $ from "jquery";
import Modal from "../../helper/modal";
import ShowGen from "./showGen";
import "./start.css";
import Ring from "./ring";
var FileSaver = require("file-saver");

class Start extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      wallet: null,
      view: "json",
      view2: "json",
      viewAbove: "generate",
      continue: false
    };
  }
  setView(view) {
    this.setState({ view });
    let _this = this;
    setTimeout(() => {
      _this.setState({ view2: view });
    }, 100);
  }
  renderModalWallet() {
    let component = (
      <div>
        <div className="col s12">
          <ShowGen wallet={this.state.wallet} />
        </div>
        <div className="col s12">
          <p className="black-text left" style={{ marginBottom: 0 }}>
            Enter BSEC Password:
          </p>
          <form onSubmit={this.saveWallet.bind(this)}>
            <input
              id={`passwordGen`}
              className="black-text"
              type="password"
              label={"Password:"}
              style={{ color: "black !important" }}
              required={true}
            />
          </form>
          <p className="black-text left" style={{ marginBottom: 0 }}>
            Confirm BSEC Password:
          </p>
          <form onSubmit={this.saveWallet.bind(this)}>
            <input
              id={`passwordGenConfirm`}
              className="black-text"
              type="password"
              label={"Password:"}
              style={{ color: "black !important" }}
              required={true}
            />
          </form>
        </div>
      </div>
    );
    return (
      <Modal
        style={{width:'500px'}}
        id="modalWallet"
        noClose={true}
        classes="modal modalOrders black-text"
        title="Generate BSEC Wallet"
        component={component}
        buttonText={"Download and Link Wallet*"}
        buttonAction={this.saveWallet.bind(this)}
        buttonText2={"Just Download Wallet*"}
        buttonAction2={this.saveWallet2.bind(this)}
        note={'*It is strongly encouraged to save this wallete in a safe and secure manner'}
      />
    );
  }
  saveWallet2(event) {
    setTimeout(() => {
      var blob = new Blob([JSON.stringify(this.state.wallet)], {
        type: "text/javascript;charset=utf-8"
      });
      FileSaver.saveAs(blob, "wallet.json");
    }, 100);
  }
  saveWallet(event) {
    if (event && event.preventDefault) event.preventDefault();
    let pass = $("#passwordGen").val();
    let pass2 = $("#passwordGenConfirm").val();
    if (pass !==pass2) {
      M.toast({ html: "Passwords don't match", classes: "red" });
      return;
    }
    if (pass2 === "") {
      M.toast({ html: "Password must be set", classes: "red" });
      return;
    }
    let _this = this;
    setTimeout(() => {
      var blob = new Blob([JSON.stringify(this.state.wallet)], {
        type: "text/javascript;charset=utf-8"
      });
      FileSaver.saveAs(blob, "wallet.json");
      _this.props.setBSECWallet(this.state.wallet, pass, (err, succ) => {
        if (err) M.toast({ html: "Unable to Link", classes: "red" });
      });
    }, 100);
  }
  async openModalWallet() {
    let wallet = await genWallet(null);
    this.setState({ wallet });
    setTimeout(() => {
      var instance = M.Modal.getInstance(
        document.querySelectorAll("#modalWallet")[0]
      );
      instance.open();
    }, 300);
  }
  renderModal() {
    let component = (
      <>
      <p style={{ color: "grey" }}>Address:</p>
      <p style={{ color: "black", marginBottom:'25px'}}>{this.state.wallet ? this.state.wallet.ethWallet.address : ''}</p>
      </>
    )
    return (
      <Modal
        style={{width:'500px'}}
        component={component}
        id="modal"
        classes="modal modalOrders black-text"
        title="Import BSEC Wallet"
        decryption={true}
        noClose={true}
        buttonText={"Confirm"}
        chain="BSEC"
        confirmPass={true}
        buttonAction={this.submitOperation.bind(this)}
      />
    );
  }
  setViewAbove(viewAbove) {
    this.setState({ viewAbove });
  }
  submitOperation() {
    let pass = $("#password").val();
    let pass2 = $("#passwordConfirm").val();
    if (pass !==pass2) {
      M.toast({ html: "Passwords don't match", classes: "red" });
      return;
    }
    let _this = this;
    setTimeout(() => {
      _this.props.setBSECWallet(this.state.wallet, pass, (err, succ) => {
        if (err) M.toast({ html: "Unable to Link Wallet", classes: "red" });
        else {
          // var instance = M.Modal.getInstance(document.querySelectorAll('#modal')[0]);
          // if(instance) instance.close();
        }
      });
    }, 100);
  }
  onDrop(files) {
    var reader = new FileReader();
    let _this = this;

    // Closure to capture the file information.
    reader.onload = (function(theFile) {
      return function(e) {
        try {
          let json = JSON.parse(e.target.result);
          if (json && (json.eth || json.ethWallet)) {
            _this.setState({ wallet: json });
            var instance = M.Modal.getInstance(
              document.querySelectorAll("#modal")[0]
            );
            instance.open();
            setTimeout(() => {
              if ($("#password")) $("#password").focus();
            }, 100);
          } else {
            M.toast({
              html: "Incorrect Wallet Format - please upload a BSEC Wallet",
              classes: "red"
            });
          }
        } catch (ex) {
          M.toast({
            html: "Incorrect Wallet Format - please upload a BSEC Wallet",
            classes: "red"
          });
        }
      };
    })(files[0]);
    reader.readAsText(files[0]);
  }
  processPriv() {
    let privKey = $("#priv")
      .val()
      .trim();
    if (privKey.substring(0, 2) === "0x") privKey = privKey.substring(2);

    let ethAddress = getETHAddress(privKey);

    if (ethAddress) {
      let wallet = {
        ethWallet: {
          address: ethAddress,
          privateKey: privKey
        }
      };
      this.setState({ wallet });
      var instance = M.Modal.getInstance(
        document.querySelectorAll("#modal")[0]
      );
      instance.open();
      setTimeout(() => {
        if ($("#password")) $("#password").focus();
      }, 100);
    } else {
      M.toast({ html: "Incorrect Private Key", classes: "red" });
    }
  }
  async processMnem() {
    let wallet = await genWallet($("#mnem").val());
    this.setState({ wallet });
    var instance = M.Modal.getInstance(document.querySelectorAll("#modal")[0]);
    instance.open();
    setTimeout(() => {
      if ($("#password")) $("#password").focus();
    }, 100);
  }
  processWallet() {
    var instance = M.Modal.getInstance(document.querySelectorAll("#modal")[0]);
    instance.open();
    setTimeout(() => {
      if ($("#password")) $("#password").focus();
    }, 100);
  }
  renderChoose() {
    let mnemStyle =
      this.state.viewAbove === "import"
        ? { color: "white" }
        : { color: "grey", opacity: 0.3 };
    let jsonStyle =
      this.state.viewAbove === "generate"
        ? { color: "white" }
        : { color: "grey", opacity: 0.3 };

    return (
      <div className="row borderless3 center">
        <div className="col s6">
          <h5
            onClick={this.setViewAbove.bind(this, "generate")}
            style={jsonStyle}
          >
            generate
          </h5>
        </div>
        <div className="col s6">
          <h5
            onClick={this.setViewAbove.bind(this, "import")}
            style={mnemStyle}
          >
            import
          </h5>
        </div>
      </div>
    );
  }
  renderGenerate() {
    return (
      <div
        className="textinput2"
        onClick={this.openModalWallet.bind(this)}
        style={{ cursor: "pointer", zIndex: 15, position: "absolute" }}
      >
        <p className="" style={{ margin: 0 }}>
          Generate BSEC Wallet To Enter
        </p>
      </div>
    );
  }
  renderImport() {
    let mnemStyle =
      this.state.view === "mnemonic"
        ? { color: "white" }
        : { color: "grey", opacity: 0.3 };
    let jsonStyle =
      this.state.view === "json"
        ? { color: "white" }
        : { color: "grey", opacity: 0.3 };
    let privStyle =
      this.state.view === "private"
        ? { color: "white" }
        : { color: "grey", opacity: 0.3 };

    return (
      <div>
        <div className="row borderless2 center">
          <div className="col s4">
            <h5 onClick={this.setView.bind(this, "mnemonic")} style={mnemStyle}>
              mnemonic
            </h5>
          </div>
          <div className="col s4">
            <h5 onClick={this.setView.bind(this, "json")} style={jsonStyle}>
              json
            </h5>
          </div>
          <div className="col s4">
            <h5 onClick={this.setView.bind(this, "private")} style={privStyle}>
              private key
            </h5>
          </div>
        </div>

        {this.state.view2 === "json" && (
          <Dropzone onDrop={this.onDrop.bind(this)}>
            {({ getRootProps, getInputProps }) => (
              <div
                className="textinput"
                style={{ cursor: "pointer", zIndex: 15, position: "absolute" }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p className="" style={{ margin: 0 }}>
                  Link BSEC Wallet
                  <br /> To Enter
                </p>
              </div>
            )}
          </Dropzone>
        )}

        {this.state.view2 === "mnemonic" && (
          <div className="enterInput">
            <div
              className="input-field col s12"
              style={{ marginBottom: "3vh" }}
            >
              <input id="mnem" type="password" className="validate startinput" />
              <label className="startlabel" for="mnem">
                Mnemonic Phrase
              </label>
            </div>
            <button
              className="btn startbtn"
              style={{ margin: 0, marginTop: "20px" }}
              onClick={this.processMnem.bind(this)}
            >
              Submit To Enter
            </button>
          </div>
        )}

        {this.state.view2 === "private" && (
          <div className="enterInput">
            <div
              className="input-field col s12"
              style={{ marginBottom: "3vh" }}
            >
              <input id="priv" type="password" className="validate startinput" />
              <label className="startlabel" for="priv">
                Private Key
              </label>
            </div>
            <button
              className="btn startbtn"
              style={{ margin: 0, marginTop: "20px" }}
              onClick={this.processPriv.bind(this)}
            >
              Submit To Enter
            </button>
          </div>
        )}
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderModal()}
        <div className="contenedor">
          {this.renderChoose()}
          {this.state.wallet &&
            this.state.viewAbove === "generate" &&
            this.renderModalWallet()}
          {this.state.viewAbove === "generate" && this.renderGenerate()}
          {this.state.viewAbove === "import" && this.renderImport()}
          <Ring />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  { setBSECWallet }
)(Start);
