import {FETCHED_FEE} from '../../types';

export function getWAVFee(amount,done){
	return function(dispatch,getState) {
    dispatch({
      type:FETCHED_FEE,
      payload: 0.001
    })
		if(done) done();
  }
}
