import {
  WALLET_SET,
  REMOVE_WALLET,
  OVERRIDE_PENDING_TRANSACTIONS,
  REMOVE_ASSET
} from '../../types';

import {getBalances} from '../get'

export function removeWallet() {
  return function(dispatch) {
    window.localStorage.removeItem('bc-wallet');
    window.localStorage.removeItem('settings');
    window.localStorage.removeItem('pendingTransactions');
    window.localStorage.removeItem('scheduleEvents');
    dispatch({
      type:OVERRIDE_PENDING_TRANSACTIONS,
      payload:{}
    });
    dispatch({
      type: REMOVE_WALLET
    });
  }
}

export function removeAsset(asset,address){
  return function(dispatch,getState){
    let wallet = getState().passport.wallet
    if(wallet[asset] && wallet[asset][address]) delete wallet[asset][address]
    dispatch({
      type: WALLET_SET,
      payload: wallet
    });
    dispatch({
      type: REMOVE_ASSET,
      payload: {asset,address}
    });
  }
}

export function saveWallet(state,type,address,enc,dispatch){
  let wallet = state.passport.wallet ? state.passport.wallet : {};
  if(!wallet[type]) {
    wallet[type] = {}
  }
  wallet[type][address] = {privateKey: enc.cipher_text, salt: enc.salt, iv: enc.iv}
  dispatch({
    type: WALLET_SET,
    payload: wallet
  });
  setTimeout(()=>{
    getBalances(dispatch,state);
  },200);
}
