import React, { Component } from 'react';
import { connect } from 'react-redux';
import Title from '../../helper/title';
import Past from './past';

class History extends Component {
  render() {
    return (
      <div className="col s12" style={{}}>
        <hr style={{margin:0,borderColor:'grey'}} />
        <Title text='history' />
        <div className='col s12' style={{padding: 0,marginTop:'5px'}}>
          <Past />
        </div>
      </div>
		)
  }
}

export default History;
